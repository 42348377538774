import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const WarningIconFilled: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  secondaryColor = 'neutral6',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <circle cx="12" cy="12" r="11" fill={getColor(secondaryColor)} />
      <path d="M11.1016 15.5186H12.8989V17.316H11.1016V15.5186Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.79736C6.36524 1.79736 1.79736 6.36524 1.79736 12C1.79736 17.6348 6.36524 22.2026 12 22.2026C17.6348 22.2026 22.2026 17.6348 22.2026 12C22.2026 6.36524 17.6348 1.79736 12 1.79736ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1011 14.5554L11.1011 5.521L12.8984 5.521L12.8984 14.5554L11.1011 14.5554Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default WarningIconFilled;
