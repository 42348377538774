import { TColor } from './colors.types';

export const getColor = (colorKey: TColor) => {
  switch (colorKey) {
    case 'currentColor':
      return 'currentColor';

    case 'primary1':
      return '#1B1C1D';
    case 'primary2':
      return '#DDD8C2';
    case 'primary3':
      return '#E8FF77';

    case 'secondary1':
      return '#C295E9';
    case 'secondary2':
      return '#FE5C2B';
    case 'secondary3':
      return '#FA9BBB';
    case 'secondary4':
      return '#40C5AE';
    case 'secondary5':
      return '#FFB514';
    case 'secondary6':
      return '#597CED';
    case 'secondary7':
      return '#5D5491';

    case 'shades1':
      return '#D1B3F2';
    case 'shades2':
      return '#E4D6F4';
    case 'shades3':
      return '#FE7D55';
    case 'shades4':
      return '#FEAD95';
    case 'shades5':
      return '#FBAFC9';
    case 'shades6':
      return '#FCCDDD';
    case 'shades7':
      return '#82CFBE';
    case 'shades8':
      return '#BFE6DF';
    case 'shades9':
      return '#FFC443';
    case 'shades10':
      return '#FFDA89';
    case 'shades11':
      return '#F4EDFC';
    case 'shades12':
      return '#FECEBF';
    case 'shades13':
      return '#D8F3EE';
    case 'shades14':
      return '#FDE1EB';
    case 'shades15':
      return '#FFEABD';

    case 'neutral1':
      return '#000000';
    case 'neutral2':
      return '#808178';
    case 'neutral3':
      return '#B8B5A5';
    case 'neutral4':
      return '#ECE9DD';
    case 'neutral5':
      return '#F4F2EB';
    case 'neutral6':
      return '#FBFBF8';
    case 'neutral7':
      return '#5B5B5B';
    case 'neutral8':
      return '#FFFFFF';
    case 'neutral9':
      return '#F0F0F0';
    case 'neutral10':
      return '#747474';
    case 'neutral11':
      return '#F3FDC4';
    case 'neutral12':
      return '#AFAFAF';
    case 'neutral13':
      return '#D9D9D9';

    case 'transparent':
      return 'transparent';

    case 'color1':
      return '#E8FF77';
    case 'color2':
      return '#1B1C1D';
    case 'color3':
      return '#FBFBF8';
    case 'color4':
      return '#D9F743';
    case 'color5':
      return '#5D5491';
    case 'color6':
      return '#D2C7FF';
    case 'color7':
      return '#F75B43';
    case 'color8':
      return '#FDCCC4';
    case 'color9':
      return '#F3FDC4';
    case 'color10':
      return '#EFEBFF';
    case 'color11':
      return '#000000';
    case 'color12':
      return '#747474';
    case 'color13':
      return '#AFAFAF';
    case 'color14':
      return '#D9D9D9';
    case 'color15':
      return '#F0F0F0';
    case 'color16':
      return '#FFFFFF';

    default:
      return getColor('primary1');
  }
};
