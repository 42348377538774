/* eslint-disable no-template-curly-in-string */
export default {
  featured: 'Mis en avant',
  popular: 'Populaires',
  couponsAndDeals: 'Coupons et offres',
  yourFavorites: 'Vos favoris',
  coupons: 'Coupons',
  headerCoupons: 'Karma | Coupons',
  lastSaved: 'Dernières sauvegardes',
  favorites: 'Favoris',
  trending: 'Tendance',
  weAreLoving: 'Nous aimons',
  karmaHome: 'Karma | Accueil',
  goodMorning: 'Bonjour',
  goodAfternoon: 'Bonjour',
  goodEvening: 'Bonsoir',
  addFavoriteStores: 'Toutes mes boutiques favorites',
  nothingFound: 'Aucun résultat',
  number: '{{value, number}}',
  clearNumber: '{{value, clearNumber}}',
  saveSettings: 'Sauvegarder les paramètres',
  enableBrand: 'Activer {{name}}',
  chrome: 'Chrome',
  edge: 'Edge',
  firefox: 'Firefox',
  ie: 'Internet Explorer',
  oldEdge: 'Edge',
  opera: 'Opera',
  safari: 'Safari',
  remove: 'Supprimer',
  sponsored: 'Sponsorisé',
  seeAll: 'Tout afficher',
  favorite: 'Favori',
  onSale: 'En promo',
  lowInStock: 'Stock faible',
  backInStock: 'Réapprovisionné',
  coupon: 'Coupon',
  soldOut: 'En rupture',
  fulfilled: 'Réalisée',
  deal: 'Offre',
  price: '{{currency}}{{value, number(minimumFractionDigits: 2)}}',
  dayRemaining: '{{count, number}} jour restant',
  dayRemaining_plural: '{{count, number}} jours restants',
  karmaStores: 'Boutiques Karma Cash',
  priceLeftToWithdraw: 'Plus que {{value, number}} {{currency}} à dépenser pour retirer vos récompenses !',
  fullPrice: '{{value, number}},{{fraction, fraction}} {{currency}}',
  fraction: '.{{value, fraction}}',
  pendingCash: 'Montant en attente',
  payouts: 'Paiements',
  connectWithPayPal: 'Connecter avec PayPal',
  selectCause: 'Sélectionnez une organisation',
  dayBetweenPayouts: "Vous pouvez retirer de l'argent {{count, number}} jour après la confirmation de l'achat.",
  dayBetweenPayouts_plural: "Vous pouvez retirer de l'argent {{count, number}} jours après la confirmation de l'achat.",
  list: 'Liste :',
  emptyWallet: 'Votre portefeuille est vide',
  startShoppingToEarn: 'Commencez à faire des achats pour gagner des récompenses !',
  withdraw: 'Retirer',
  yourDonations: 'Vos dons',
  outOfAmount: 'Sur {{value, number}}{{currency}}',
  gettingCloser: 'Vous approchez du but !',
  canWithdrawEarnings: 'Vous pouvez maintenant retirer vos gains.',
  workedAt: 'A fonctionné le {{date}}',
  copied: 'Copié !',
  getCoupon: 'Obtenir le coupon',
  shopNow: 'Acheter maintenant',
  karmaLogo: 'Logo de Karma',
  stores: 'Boutiques',
  myLists: 'Mes listes',
  uppercase: '{{text, uppercase}}',
  capitalize: '{{text, capitalize}}',
  all: 'Toutes',
  lastWeek: 'La semaine dernière',
  lastMonth: 'Le mois dernier',
  lastThreeMonths: 'Les trois derniers mois',
  clickID: 'Click ID',
  date: 'Date',
  time: 'Heure',
  retailer: 'Commerçant',
  orderID: 'ID de la commande',
  startShoppingToEarnKarmaCash: 'Commencez à faire des achats pour gagner du Karma Cash :',
  browseStores: 'parcourir les boutiques',
  doNotHaveClicks: "Vous n'avez aucun clic pour cette période.",
  doNotHaveTransactions: "Vous n'avez aucune transaction pour cette période.",
  payPalAlt: 'PayPal',
  payPal: 'PayPal :',
  welcomeBonus: 'Bonus de bienvenue !',
  karmaGives: 'Karma Gives !',
  karmaGivesRefund: 'Remboursement Karma Gives !',
  completed: 'Terminé',
  notQualified: 'Non qualifié',
  pending: 'En attente',
  cancelled: 'Annulée',
  paid: 'Payée',
  empty: 'Vide',
  expired: 'Expirée',
  discoverAndShop: 'Découvrir et acheter',
  contactUs: 'Contactez-nous',
  news: 'Infos',
  faq: 'FAQ',
  aboutUs: 'À propos de nous',
  karmaItem: 'Produit Karma',
  email: 'E-mail',
  congrats: 'Félicitations !',
  congratulations: 'Félicitations !',
  getKarmaButton: 'Obtenez le bouton Karma !',
  saveThingsToBuy: 'Sauvegardez les choses que vous voulez acheter plus tard avec Karma !',
  savedFirstItem: 'Vous avez sauvegardé votre premier produit !',
  getTheButton: 'Obtenir le bouton',
  savedFirstItem_plural: 'Vous avez déjà sauvegardé quelques produits !',
  iWantToSeeOther: "Je veux voir d'autres <1>boutiques compatibles</1>",
  neverMissProducts: 'Vous ne raterez plus jamais les produits mis en vente ou réapprovisionnés !',
  shareByEmail: 'Partager par e-mail',
  whenIFindSmth: 'Quand je trouve ce que je veux, je le sauvegarde sur Karma !',
  completeSignUp: "Finaliser l'inscription",
  addToList: 'Ajouter à la liste',
  chat: 'Tchat',
  karmaCash: 'Karma Cash',
  clickToSaveItem: 'Cliquez pour sauvegarder un élément',
  createListAndPressEnter: 'Créer une liste et appuyez sur « Entrée »',
  confirmDeleteProduct: 'Voulez-vous vraiment supprimer ce produit ?',
  shareOnFacebook: 'Partager sur Facebook',
  selectAll: 'Tout sélectionner',
  selectProp: 'Sélectionner un {{name}}',
  delete: 'Supprimer',
  fulfill: 'Compléter',
  mute: 'Désactiver',
  anyPriceChange: 'Toute modification de prix',
  priceOff: '-{{value, number}} %',
  notifyWhen: 'Notifier quand',
  tweetOnTwitter: 'Tweet sur Twitter',
  shareProduct: 'Partager le produit',
  markAsFulfilled: 'Marquer comme accompli',
  unmuteNotifications: 'Activer les notifications',
  muteNotifications: 'Désactiver les notifications',
  priceEvolution: 'Évolution du prix',
  logOut: 'Déconnexion',
  twitter: 'Twitter',
  facebook: 'Facebook',
  instagram: 'Instagram',
  whatsapp: 'WhatsApp',
  messenger: 'Messenger',
  privacyPolicy: 'Politique de confidentialité',
  confirmDelete: 'Voulez-vous vraiment supprimer ces produits ?',
  termsAndConditions: "Conditions d'utilisation",
  logosProvidedBy: 'Logos fournis par {{name}}',
  copyright:
    'Copyright © {{year, clearNumber}} Karma Shopping Ltd., exerçant son activité en tant que Karma. Tous droits réservés.',
  noNotifications: 'Aucune notification',
  clearAllNotifications: 'Effacer toutes les notifications',
  areYouSureRemoveNotifications: 'Voulez-vous vraiment effacer toutes les notifications ?',
  deleteProducts: 'Supprimer les produits',
  no: 'Non',
  yes: 'Oui',
  headerStores: 'Karma | Boutiques',
  clearNotification: 'Effacer la notification',
  clear: 'Effacer',
  bell: 'cloche',
  remindInMinute: 'Me le rappeler dans {{value, number}} minute',
  remindInMinute_plural: 'Me le rappeler dans {{value, number}} minutes',
  remindInHour: 'Me le rappeler dans {{value, number}} heure',
  remindInHour_plural: 'Me le rappeler dans {{value, number}} heures',
  retailers: 'Commerçants',
  claimKarmaCash: 'Obtenir jusqu’à {{percent, number}} % de Karma Cash',
  clickOnClaimButton: "Cliquez sur le bouton « Obtenir jusqu'à {{percent, number}} % de Karma Cash ».",
  goShopping: 'Commencer à acheter',
  rackUpCash: "Accumulez de l'argent",
  getPaidDirectly: 'Faites-vous payer directement',
  earnRealMoney: "Gagnez de l'argent sur tous vos achats.",
  trackYourEarnings: 'Analysez vos gains et encaissez à chaque saison.',
  ordinalNumber: '{{value, fraction}}',
  relatedStores: 'Boutiques associées',
  defaultWhatHappensNext:
    'Pour gagner du Karma Cash automatiquement lors de votre prochain achat, cliquez sur l’un des magasins éligibles au Karma Cash sur Karma. Vous pourrez alors continuer d’acheter comme d’habitude ! N’oubliez pas que vous ne pouvez gagner du Karma Cash que dans les magasins éligibles via l’application ou le tableau de bord de Karma (y compris depuis les notifications push) ou en cliquant sur l’extension Karma du navigateur de votre ordinateur/mobile avant l’achat. Une fois votre achat validé par le commerçant, Karma confirmera vos gains dans votre portefeuille Karma.',
  defaultTermsAndConditions:
    'Vous ne recevrez pas de Karma Cash pour l’achat de cartes cadeaux ni pour toute partie d’un achat payé avec une carte cadeau, un avoir du magasin ou des points de fidélité ou tout autre programme de points. L’utilisation de codes promotionnels non répertoriés sur le site web de Karma peut annuler le Karma Cash. D’autres restrictions peuvent s’appliquer à certains produits ou catégories.',
  whatHappensNext: 'Que se passe-t-il ensuite ?',
  about: 'À propos',
  readMore: 'En savoir plus',
  frequentlyAskedQuestions: 'Foire aux questions',
  loadMore: 'Charger plus',
  questionButton: 'bouton question',
  headerKarmaCash: 'Karma | Récompenses en cash',
  recommended: 'Recommandé',
  highToLow: 'Ordre décroissant',
  lowToHigh: 'Ordre croissant',
  sortBy: 'Trier par :',
  exploreStores: 'Explorer les boutiques',
  weHaveNotFoundStores: "Nous n'avons trouvé aucune boutique correspondant à vos critères",
  weHaveNotFoundCoupons: "Nous n'avons trouvé aucun coupon correspondant à vos critères",
  headerApps: 'Karma | Applis',
  karmaApps: 'Recevez du bon karma, partout.',
  stayUpdated:
    "Restez informé sur tous vos appareils. Notre assistant d'achat numérique vous permet de sauvegarder les produits de plus de {{number, number}} 000 boutiques.",
  getFaster: "Profitez d'une expérience plus rapide et plus fluide en téléchargeant Karma sur votre Mac.",
  headerProfile: 'Karma | Profil',
  headerWallet: 'Karma | Portefeuille',
  totalBalance: 'Solde total',
  editYourAccount: 'Modifier votre compte',
  emailAddress: 'Adresse e-mail',
  enterEmailAddress: "Saisissez l'adresse e-mail",
  enterEmail: "Saisissez l'e-mail",
  enterEmailLower: "Saisissez l'e-mail",
  phoneNumber: 'Numéro de téléphone',
  enterPhoneNumber: 'Saisissez le numéro de téléphone',
  fullName: 'Nom complet',
  enterFullName: 'Saisissez le nom complet',
  enterPlaceholder: 'Saisissez {{name}}',
  birthday: 'Anniversaire',
  year: 'Année',
  month: 'Mois',
  day: 'Jour',
  gender: 'Sexe',
  language: 'Langue',
  currency: 'Devise',
  multiCurrency: 'Multidevises',
  male: 'Masculin',
  female: 'Féminin',
  notWantShare: 'Je ne veux pas partager',
  mobilePush: 'Mobile push',
  webPush: 'Web push',
  disconnectFromPayPal: 'Déconnecter de PayPal',
  loginAsUser: 'FUT',
  selectYearAndMonth: "Veuillez sélectionnez l'année et le mois",
  notSureWhereToStart: 'Vous ne savez pas par où commencer ?',
  youDidntSaveItems: "Vous n'avez sauvegardé aucun produit.",
  searchItems: 'Rechercher des produits',
  headerDashboard: 'Karma | Tableau de bord',
  headerSales: 'Karma | Promotions',
  couldNotFindStores: "Oups, nous n'avons pas trouvé de promotion",
  karmaGivesValue: '{{value, number}} $',
  karmaGivesModalTitle: 'Voici {{value, number}} $ GRATUITS pour commencer',
  hereIsABitOfGoodKarma: 'Voici un peu de bon karma pour vous remercier de vous être inscrit !',
  collectAndStartSaving: 'Collectez et commencez à faire des économies',
  termsAndConditionsAppersant: "Conditions d'utilisation",
  mayApply: "peuvent s'appliquer",
  termsAndConditionsError:
    "Veuillez tout d'abord accepter nos conditions d'utilisation et notre politique de confidentialité.",
  validEmailError: 'Veuillez saisir une adresse e-mail valide.',
  completeSignUpCapital: "Finalisez l'inscription",
  or: 'OU',
  password: 'Mot de passe',
  joinUs: 'Rejoignez-nous',
  reviewsPlus: '+ de {{amount, number}} 000 avis',
  logIn: 'Connexion',
  termsAgreement:
    "En vous connectant, vous acceptez nos <1>Conditions d'utilisation</1> et notre <3>Politique de confidentialité</3>",
  termsAgreementWithCheckbox: "J'accepte les <1>Conditions d'utilisation</1> et la <3>Politique de confidentialité</3>",
  createANewList: 'Créer une nouvelle liste',
  listCreateBlickError: 'Le nom de la liste ne peut pas être vide',
  enterListName: 'Saisissez le nom de la liste',
  listDescriptionOptional: 'Description de la liste (facultative)',
  listName: 'Nom de la liste',
  enterDescriptionForList: 'Saisissez une description pour votre liste',
  save: 'Sauvegarder',
  deleteList: 'Supprimer la liste',
  areYouSureRemoveList: 'Voulez-vous vraiment supprimer cette liste ?',
  editList: 'Modifier la liste',
  listNameCanNotBeBlanck: 'Le nom de la liste ne peut pas être vide',
  shareList: 'Partager la liste',
  notAMemberCreateAccount: 'Pas encore membre ? <1>Créez un compte !</1>',
  backToLogin: "Retour à l'identification",
  enterYourEmail: 'Saisissez votre adresse e-mail',
  resetPassword: 'Réinitialiser le mot de passe',
  forgotPasswordMessage:
    'Vous recevrez un e-mail contenant les instructions sur la manière de réinitialiser votre mot de passe dans quelques minutes.',
  forgotPassword: 'Mot de passe oublié ?',
  reCaptchaMessage:
    "Ce site est protégé par reCAPTCHA et la <1>Politique de confidentialité</1> et les <3>Conditions d'utilisation</3> de Google s'appliquent",
  pleaseScan: 'Veuillez scanner',
  saveAnItem: 'Sauvegarder un produit',
  areYouSureFulfilled: 'Voulez-vous vraiment marquer ces produits comme étant « accomplis » ?',
  muteTagDesc: 'Voulez-vous vraiment désactiver les notifications pour ces produits ?',
  muteTagTitle: 'Désactiver les notifications',
  ok: 'OK',
  canAccessEarningsInPayPal: 'Vous pouvez accéder à vos gains dans votre compte PayPal',
  ccpaTitle: 'Le California Consumer Privacy Act (CCPA), amendé par le California Privacy Rights Act (CPRA)',
  ccpaText: " En envoyant ce formulaire, j'exerce mon droit d'opposition à toute vente de mes données personnelles.",
  submit: 'Envoyer',
  joinKarmaAndGetRewards: 'Rejoignez Karma et recevez {{stake, number}} récompenses chez {{name}}',
  doNotMissOut: "Ne ratez rien. Vous pourrez peut-être économiser de l'argent sur votre produit !",
  signUpAndGetMoney: 'Inscrivez-vous et recevez {{value, number}} $',
  itemAlert: 'Vous avez une alerte pour un produit !',
  accessKarmaCash: 'Accédez à votre Karma Cash',
  youEarnedKarmaCash: 'Karma Cash gagné !',
  verifyYourPaypalAccount: 'Vérifiez votre compte<1/>Paypal',
  weHaveSentAnEmailTo: 'Nous avons envoyé un e-mail à',
  installed: 'Installé',
  'Fall Favorites': "Favoris d'automne",
  'Karma Cash': 'Karma Cash',
  'Clothing & Luxury': 'Vêtements et luxe',
  'Home Decor & Furniture': 'Meubles et déco',
  'Electronics & Office Supplies': 'Électronique et fournitures de bureau',
  Luxury: 'Luxe',
  Clothing: 'Vêtements ',
  'Handbags & Shoes': 'Sacs à main et chaussures',
  'Jewelry & Watches': 'Bijoux et montres',
  Accessories: 'Accessoires',
  'Beauty Essentials': 'Essentiels de beauté',
  'Health & Wellness': 'Santé et bien-être',
  Lifestyle: 'Style de vie',
  'Sports & Outdoors': 'Sports et plein air',
  Travel: 'Voyage',
  'Electronics & Office supplies': 'Électronique et fournitures de bureau',
  'Entertainment & Online Services': 'Divertissements et services en ligne',
  Education: 'Éducation',
  'Finance & FinTech': 'Finance et FinTech',
  'Pet Supplies': 'Fourniture pour animaux',
  'Food & Beverages': 'Nourriture et boissons',
  Men: 'Homme',
  Women: 'Femme',
  Unisex: 'Unisexe',
  Kids: 'Enfants',
  Baby: 'Bébé',
  notifications: 'Notifications',
  changesSaved: 'Modifications sauvegardées !',
  headerRetailer: 'Karma | Commerçant',
  headerRedirect: 'Karma | Redirection...',
  karma: 'Karma',
  goBackToKarma: 'Revenir sur Karma',
  authentication: 'Authentification',
  authSuccess: 'Félicitations ! Vous venez de vous connecter.',
  authFailure: "Oups, nous n'avons pas pu vous autoriser. Réessayez plus tard.",
  mayNotQualify: 'Certains produits ne sont pas qualifié pour du Karma Cash',
  checkOutRetailers: 'Découvrez les commerçants Karma Cash compatibles.',
  headerHowItWorks: 'Karma | Comment ça marche',
  accept: 'Accepter',
  headerError: 'Karma | Erreur',
  pageNotFound: 'Page non trouvée',
  pageDoesNotExist: "Oups, il semblerait que cette page n'existe pas.",
  backHome: "Retour à l'accueil",
  startShopping: 'Commencer à acheter',
  illustration: 'Illustration',
  dashboard: 'Tableau de bord',
  allRightsReserved: 'Tous droits réservés.',
  product: 'Produit',
  disclaimer:
    "Karma Shopping Ltd, exerçant son activité en tant que Karma. Karma n'a pas, et ne prétend pas avoir, la propriété des marques de sociétés tierces. Les marques de sociétés tierces visibles, accessibles directement ou indirectement via la plateforme Karma n'ont pas nécessairement de relation avec Karma ou des sociétés tierces associés à Karma ou des prestataires de services de Karma.",
  help: 'Aide',
  team: 'Équipe',
  howItWorks: 'Comment ça marche',
  supportedStores: 'Boutiques compatibles',
  company: 'Société',
  planYourPurchase: 'Planifiez votre prochain achat avec notre application gratuite !',
  itSitsInBrowser: 'Il se trouve sur votre navigateur web quand vous parcourez vos magasins préférés.',
  seeSmthYouLike:
    'Vous voyez quelque chose qui vous plaît ? Cliquez sur le bouton pour le sauvegarder dans votre liste et Karma gardera un œil sur son prix et sa disponibilité.',
  youWillGetNotified:
    "Vous serez averti instantanément lorsque l'un des articles que vous avez sauvegardés sera mis en vente, que son prix baissera ou qu'il sera réapprovisionné dans la taille et la couleur de votre choix.",
  everythingYouWant:
    'Vous retrouverez au même endroit tout ce que vous voulez acheter, pour une organisation optimale et ne plus avoir à jongler avec plusieurs listes de souhaits.',
  headerContactUs: 'Karma | Contactez-nous',
  needToGetInTouch: 'Besoin de nous contacter ?',
  dropALine: 'Écrivez-nous un mot',
  comeHangOut: 'Venez discuter',
  joinOurFacebookCommunity: 'Rejoignez notre communauté Facebook',
  followOnInstagram: 'Suivez-nous sur Instagram',
  headerAboutUs: 'Karma | À propos de nous',
  addTheItems:
    "Ajoutez les produits que vous souhaitez acheter (vous pouvez même personnaliser la taille et la couleur) et l'application vous avertira lorsqu'ils seront disponibles.",
  jonathanSay:
    "Jonathan : alors que les commerçants tirent parti de l'IA depuis des années, c'est désormais le consommateur qui va profiter des outils d'apprentissage automatique et d'automatisation pour gagner en efficacité.",
  justDownload:
    "Téléchargez simplement un « bouton » et cliquez dessus lorsque vous vous trouvez sur la page d'un produit qui vous plaît pour le sauvegarder dans votre liste. Sur mobile, vous pouvez « partager » des produits directement dans les mêmes listes.",
  ifYouFind:
    "Si vous trouvez un article qui vous plaît en ligne, mais qui dépasse votre budget, vous pouvez créer une alerte promo afin d'être averti lorsque le prix baisse.",
  onlyAHandful:
    "Seule une poignée d'entreprises proposant des alertes promo pour leurs membres en ligne continuent à prospérer. C'est le cas de Karma.",
  alwaysBuy: "Vous n'achèterez plus que des produits en promo avec Karma.",
  headerCareers: 'Karma | Carrières',
  getInTouch: 'Contactez-nous :',
  shop: 'Acheter',
  weCouldNotFindResults: "Nous n'avons trouvé aucun résultat pour « {{name}} »",
  couldNotFindResults: "Nous n'avons trouvé aucun résultat",
  shopAllStores: 'Parcourez toutes les boutiques compatibles',
  popularStores: 'Boutiques populaires',
  shops: 'Boutiques',
  headerDiscoverAndShop: 'Karma | Découvrez et achetez les derniers produits sauvegardés',
  prev: 'Préc.',
  next: 'Suiv.',
  headerDemo: 'Karma | Démo',
  getTheKarmaButton: 'Obtenir le bouton Karma',
  installedKarma: 'Vous avez bien installé Karma !',
  settingUpAccount: 'Commençons par configurer votre compte.',
  signUpNow: "S'inscrire maintenant",
  logInNow: 'Connexion',
  signUpLater: "S'inscrire plus tard",
  wePartnerWith: 'Nous avons un partenariat avec plus de {{number, number}} 000 boutiques en ligne !',
  pickStoresToSee: "Choisissez l'une des boutiques ci-dessous pour voir comment ça marche :",
  leaveKarma:
    "Notez que vous devrez quitter Karma et vous rendre sur la page de la boutique pour profiter pleinement de l'expérience.",
  searchFavoriteStores: 'Recherchez vos boutiques préférées',
  clickBelowToStart: 'Cliquez ci-dessous pour commencer',
  chooseOneOfTheOptions: "Choisissez l'une des options ci-dessous",
  clickToBegin: 'Cliquez sur la barre de recherche pour commencer',
  preferNotToSay: 'Je préfère ne pas le dire',
  done: 'Terminé',
  headerUnsubscribe: 'Karma | Se désabonner',
  changeMind: "Vous avez changé d'avis ?",
  resubscribeList: 'Se réabonner à la liste de diffusion.',
  resubscribeList_plural: 'Réabonnez-vous à votre liste de diffusion ici.',
  changePreferencesAtAnyTime:
    'Vous pouvez aussi modifier vos préférences à tout moment dans les <1>paramètres du compte.</1>',
  preferencesUpdated: "Nous avons modifié vos préférences en matière d'e-mail.",
  sorryToSeeYouGo: 'Nous sommes désolés de vous voir partir !',
  reSubscribed: 'Vous vous êtes réabonné',
  allowHours: 'Veuillez patienter {{hours, number}} heures pour que les modifications soient effectives.',
  missWhenYouGone:
    "Vous nous manquerez quand vous serez parti ! Veuillez patienter jusqu'à {{hours, number}} heures pour que les modifications soient effectives.",
  headerDisablingSubscribe: "Karma | Désactiver l'abonnement",
  noLongerAlerts: "Vous ne recevrez plus d'alertes pour ce produit.",
  turnAlertsBack: 'Activer les alertes.',
  moveAnimations: 'Animation du déplacement du bouton',
  headerExtensionDeleted: 'Karma | Extension supprimée',
  areSorryToSeeYouGo: 'Nous sommes désolés de vous voir partir !',
  youHaveGotInYourWallet: 'Vous avez {{value}} $ dans votre portemonnaie.',
  toWithdraw: ' pour retirer vos gains !',
  toContinue: ' pour continuer à gagner des récompenses !',
  reasonUninstalled: "Pourriez-vous nous dire pourquoi vous l'avez désinstallée :",
  getInTouchBtn: 'Restons en contact',
  howWeCanIncrease:
    'Voici comment vous pouvez accroître la notoriété de votre marque et stimuler les ventes en faisant de la publicité avec Karma',
  helpingShoppers:
    "L'une de nos principales priorités, en tant qu'assistant personnel d'achat, c'est d'aider les consommateurs à découvrir des marques qu'ils apprécieront pendant des années.",
  whyWeOfferBrands:
    'Voilà pourquoi nous proposons aux marques apportant une entière satisfaction à leurs clients de multiples façons de se connecter avec nos utilisateurs. Vous atteignez vos objectifs de campagne et nos utilisateurs trouvent leur nouvelle marque préférée.',
  winWin:
    "Nous proposons aux marques apportant une entière satisfaction à leurs clients de multiples façons de se connecter avec nos utilisateurs. Vous atteignez vos objectifs de campagne et nos utilisateurs trouvent leur nouvelle marque préférée. C'est gagnant-gagnant !",
  getFeaturedRight:
    'Vous apparaissez directement sur le tableau de bord de nos applications et extensions de navigateur.',
  getFeaturedDashboard: 'Apparaissez sur le tableau de bord de Karma',
  putYourHand:
    'Placez votre marque, vos produits ou vos promotions devant les yeux de nos utilisateurs au moment où ils sont prêts à effectuer un achat. Nous pouvons créer une variété de contenus de marque personnalisés qui dirigeront le trafic là où vous le souhaitez.',
  exactPlace: "Placez votre marque sous les yeux de nos utilisateurs au moment où ils sont prêts à passer à l'achat.",
  dedicatedInAppPlacement: 'Placement intégré dédié',
  dashboardFeaturedBanners: 'Bannières de présentation sur le tableau de bord',
  dashboardPopUpMessages: 'Pop-ups sur le tableau de bord',
  customEmailsAndNewsLetters:
    'E-mails personnalisés et placement dans notre newsletter avec plus de {{subscribers, number}} abonnés',
  customEmails: 'Des e-mails personnalisés à plus de {{subscribers, number}} abonnés',
  getMoreEyes:
    "Attirez l'attention sur votre dernière offre, votre code de réduction ou votre gamme de produits grâce à notre newsletter et à nos e-mails d'informations dédiés aux acheteurs.",
  putYourBrand: "Placez votre marque sous les yeux de nos utilisateurs au moment où ils sont prêts à passer à l'achat.",
  newsletterInclusion: 'Inclusion dans la newsletter',
  dedicatedCustomEmails: '{{percent, number}} % e-mails personnalisés dédiés',
  bannerPlacementInEmails: 'Placement de bannière dans les e-mails de notification',
  pushNotificationsToShoppers: 'Notifications push pour plus de {{shoppers, number}} consommateurs',
  pushNotificationsGiveTouchPoint:
    "Les notifications push donnent à votre campagne un autre point de contact et offrent des taux d'ouverture et de clic élevés.",
  featureBrandClients: 'Clients de marque mis en avant',
  growingPerMonth: 'Plus de {{number, number}} 000 par mois, en croissance',
  members: 'Membres',
  percent: '{{value, number}} %',
  kindOfReachYouExpect: 'Quel genre de portée pouvez-vous espérer ?',
  ourAudience: 'Nos utilisateurs',
  tabIntoNewSource: 'Exploitez une nouvelle source de clients identifiés pour votre prochaine campagne',
  tellUsAboutYourCompany:
    'Parlez-nous de votre entreprise et de vos objectifs et nous vous montrerons comment nous pouvons vous aider à les dépasser.',
  openAndTap:
    'Ouvrez Safari, accédez à la page du produit que vous souhaitez sauvegarder et appuyez sur le bouton « Partager ».',
  shareIllustration: 'Illustration du partage',
  tapMore: 'Appuyez sur le bouton « Plus » à droite du panneau.',
  moreIllustration: 'Illustration de plus',
  enableIllustration: "Illustration de l'activation",
  tapEdit: 'Appuyez sur le bouton « Modifier » en haut du panneau.',
  enableApp:
    "Activez Karma et appuyez sur le bouton vert « Ajouter » pour l'ajouter à vos favoris, puis appuyez sur « Terminé ».",
  goBackToTheProduct:
    'Retournez sur la page du produit, appuyez à nouveau sur le bouton « Partager », puis sur le bouton « Karma » pour sauvegarder votre produit.',
  recommendedBy: 'Recommandé par',
  worldIsChanging:
    "Le monde change. Il est temps d'acheter plus intelligemment ! Rejoignez plus de {{number, number}} millions de membres et commencez à faire des économies.",
  ourMembersSavingMoney: "Nos membres ❤️ économisent de l'argent avec Karma. Voilà pourquoi.",
  ourMembersSavingMoney2: "Nos membres <1/>️ économisent de l'argent avec Karma. Voilà pourquoi.",
  addToBrowser: "Ajoutez-le à {{browser}} - C'est gratuit",
  planPurchases: 'Planifiez vos prochains achats',
  karmaMakesItEasy: "Karma vous permet d'organiser facilement vos prochains achats en un seul endroit.",
  buyAtRightTime: 'Achetez au bon moment',
  automaticallyAlert: "Karma vous alerte automatiquement lorsque c'est le bon moment pour acheter.",
  cashOnVacation: 'Karma Cash sur vos prochaines vacances',
  karmaPartners: "Karma est partenaire de nombreux sites de voyages et d'hôtels.",
  headerRewards: 'Karma | Meilleurs coupons et récompenses en cash',
  mission: 'Notre mission',
  empowerYou: 'Vous permettre de faire les bons choix au bon moment lorsque vous achetez en ligne.',
  weAreSeriousAboutPrivacy: 'Votre confidentialité et votre sécurité sont primordiales',
  weDoNotSellData: 'Nous ne vendons pas vos données',
  karmaIsSerious: 'Karma prend le respect de vos données très au sérieux et ne les vendra jamais à des tiers. Jamais.',
  karmaIsSecure: 'Karma est sécurisé',
  weUseBankSecurity:
    'Nous utilisons une sécurité de niveau bancaire (cryptage à {{bit, number}} bits) pour garantir la sécurité de vos données.',
  helpsSaveMore: "Karma vous aide à faire plus d'économies !",
  alertMe: 'Alertez-moi',
  buyWithKarmaEarn: 'Achetez avec Karma - Gagnez {{number, number}} $',
  productSaved:
    'Félicitations ! Ce produit a été enregistré dans votre liste de produits. Vous serez averti lorsque son prix baissera.',
  willApplyTheBestCoupons: 'Karma appliquera automatiquement les meilleurs coupons à votre achat !',
  downloadAndAutoApply: 'Télécharger Karma et appliquer automatiquement',
  whatIsKarma: "Qu'est-ce que Karma ?",
  claimUpToRewards: "Réclamez jusqu'à {{number, number}} % de récompenses en cash",
  youMightAlsoLike: 'Vous pourriez aussi aimer',
  weAreSorry: 'Nous sommes désolés, mais quelque chose a mal tourné et la page ne peut être affichée.',
  tryToReloadPage: 'Pourriez-vous essayer de recharger la page ?',
  Color: 'Couleur',
  Size: 'Taille',
  latestCoupons: '{{name}} | Les derniers coupons et cashback de Karma {{year, clearNumber}}',
  neverPayFullPrice: 'Ne payez plus le plein tarif. Recevez des coupons et du cashback chez {{name}} de Karma',
  neverPayFullPriceForTag: 'Ne payez plus le plein tarif pour {{name}}',
  saveMoneyWhenShoppingAt: 'Faites des économies en achetant chez {{name}}. Rejoignez Karma gratuitement',
  saveMoneyWhenShoppingFor: 'Faites des économies en achetant chez {{name}}. Rejoignez Karma gratuitement',
  tagByBrand: 'Karma | {{tag}} par {{brand}}',
  typeToSearch: 'Écrivez pour rechercher',
  getUSDWhenJoinToday: "Obtenez {{value, number}} $ en vous inscrivant sur Karma aujourd'hui !",
  buyProduct: 'Acheter le produit',
  userWishList: 'Karma | Liste de souhaits {{name}}',
  byWhom: 'par',
  copiedToClipboard: 'Copié dans le presse-papier !',
  send: 'Envoyer',
  cause: 'Choisir cause',
  aCause: 'Choisir une cause',
  close: 'Fermer',
  selectThisCause: 'Choisir cette cause',
  habitatForHumanity:
    "{{name}} se consacre à la réduction de la pauvreté à long terme grâce à des logements sociaux abordables. Sa mission consiste à créer un environnement sûr où les familles et les communautés peuvent s'épanouir.",
  casaDescription:
    "L'association nationale CASA/GAL soutient et promeut la défense des intérêts des bénévoles nommés par le tribunal afin que chaque enfant victime d'abus ou de négligence puisse vivre en sécurité, avoir un foyer permanent et la possibilité de s'épanouir",
  endHomelessnessDescription:
    "L'Alliance nationale pour {{name}} est une organisation non partisane engagée dans la prévention et la fin de l'itinérance aux États-Unis",
  pathDescription: "PATH met fin à l'itinérance pour les individus, les familles et les communautés",
  feedingAmericaDescription: 'Feeding America est la plus grande organisation nationale de lutte contre la faim',
  mealsOnWheelsDescription:
    "{{name}} donne aux programmes communautaires locaux les moyens d'améliorer la santé et la qualité de vie des personnes âgées qu'ils servent, afin que personne ne souffre de la faim ou ne soit isolé",
  actionAgainstHunger:
    '{{name}} est une organisation humanitaire mondiale qui prend des mesures décisives contre les causes et les effets de la faim',
  supportMedicalAdvancement: 'Soutenir la recherche médicale',
  saveChildsHeartDescription:
    "Save A Child's Heart répare les cœurs sans distinction de race, de religion, de sexe, de nationalité ou de situation financière",
  su2c: "Stand Up To Cancer (SU2C) finance et développe les traitements anticancéreux les plus récents et les plus prometteurs pour aider les patients d'aujourd'hui",
  drWithoutBorders:
    '{{name}} est un mouvement mondial indépendant qui fournit une aide médicale là où elle est le plus nécessaire',
  activeMinds:
    "{{name}} est la première organisation à but non lucratif du pays qui soutient la sensibilisation et l'éducation à la santé mentale des jeunes adultes",
  cleanAirTaskForceDesc:
    '{{name}} stimule le changement dans les technologies et les politiques nécessaires pour arriver à une planète zéro émission et à haute énergie à un coût abordable',
  wwfDesc:
    'La mission de {{name}} est de préserver la nature et de réduire les menaces les plus urgentes à la diversité de la vie sur Terre',
  waterkeepsDesc:
    "{{name}} se bat pour une eau propre. Waterkeeper Alliance s'efforce de garantir que chaque communauté dans le monde dispose d'une eau potable, pêchable et baignable",
  xprice:
    "Chaque jour, XPrize s'efforce de créer un monde meilleur où chacun a accès à de l'eau potable, à des aliments nutritifs, à un logement abordable, à un apprentissage efficace, à des soins médicaux de haut niveau et à une énergie abondante et non polluante",
  globalActDesc: "{{nom}} œuvre pour mettre fin à l'exploitation sexuelle et commerciale",
  pcaa: "{{name}} travaille dur pour réaliser notre vision d'un monde où tous les enfants grandissent heureux, en bonne santé et prêts à réussir dans des familles et des communautés solidaires",
  endCAN:
    "EndCAN sensibilise aux effets de la maltraitance sur la santé, l'état mental et la santé publique avec pour mission de mettre fin à la maltraitance et à la négligence envers les enfants",
  futuresDesc:
    'FUTURES propose des programmes, des politiques et des campagnes révolutionnaires qui habilitent les individus et les organisations qui travaillent à mettre fin à la violence contre les femmes et les enfants dans le monde',
  thornDesc: '{{name} met en place une technologie pour défendre les enfants contre les abus sexuels.',
  'Eradicate Homelessness': "Éradiquer l'itinérance",
  'Help End Hunger': 'Aider à la lutte contre la faim',
  'Support Medical Advancement': 'Soutenir la recherche médicale',
  'Combat Climate Change': 'Agir pour mettre fin aux abus',
  'Act To End Abuse': 'Agir pour mettre fin aux abus',
  itemsYouSaved: 'Articles que vous avez sauvegardés pour moins',
  beta: 'BÊTA',
  alsoAvailableAt: 'Également disponible chez',
  atName: 'chez {{name}}',
  bestPrice: 'Meilleur prix',
  passwordWasSuccessfullyReset: 'Le mot de passe a été réinitialisé avec succès',
  paidWith: 'Payé avec',
  infoFirstNumber: '{{number, number}}{{unit}}+',
  infoFirstTitle: 'Membres',
  infoSecondNumber: '{{number, number}}{{unit}}+',
  infoSecondTitle: 'Visites mensuelles',
  infoThirdNumber: '{{number, number}}{{unit}}+',
  infoThirdTitle: 'Liste de diffusion',
  infoFourthNumber: '{{value, number}}%',
  infoFourthTitle: 'Génération X',
  infoFifthNumber: '{{value, number}}%',
  infoFifthTitle: 'Femmes',
  infoSixthNumber: '{{value, number}}%',
  infoSixthTitle: 'Situés aux États-Unis',
  infoSeventhNumber: '{{number, number}}{{unit}}+',
  infoSeventhTitle: 'Activer les notifications push sur mobile',
  infoEighthNumber: '{{number, number}}{{unit}}+',
  infoNinthPreNumber: '',
  infoNinthNumber: '${{number, number}}{{unit}}+',
  infoNinthTitle: 'Seront dépensés par les utilisateurs avant la fin de {{year, clearNumber}}',
  karmaIsAlwaysWithYou: 'Karma vous accompagne toujours lorsque vous faites vos achats',
  saveItemsToBuy: 'Sauvegardez des articles et nous vous dirons quand acheter',
  weDoTheWork: "Nous faisons tout ce qu'il faut pour vous trouver les meilleurs coupons",
  earnRealCashThatAdds: "Gagnez de l'argent réel qui s'accumule dans votre portefeuille",
  setUpStrongPassword: 'Configurer un mot de passe fort',
  settingEmailAccount:
    "La dernière étape pour économiser de l'argent avec Karma ? Inscrivez-vous pour que nous puissions vous informer des alertes importantes sur vos articles, comme des baisses de prix",
  settingPasswordAccount:
    "Le mot de passe doit être composé d'un minimum de {{number, number}} caractères et il ne doit pas inclure vos identifiants de messagerie.",
  countWaysToUse: "{{number, number}} façons d'utiliser Karma",
  goBack: 'Retour',
  pleaseChooseStrongerPassword: 'Veuillez choisir un mot de passe plus fort.',
  pleaseEnterValidEmailAddress: 'Veuillez saisir une adresse e-mail valide.',
  illSignUpLater: "Je m'inscrirai plus tard",
  enterYourEmailAddress:
    'Veuillez saisir votre adresse électronique et nous vous enverrons un lien pour réinitialiser votre mot de passe.',
  headerOnboardingIosExtension: "Karma | Intégration de l'extension IOS ",
  weWillAutomaticallyApplyCouponsWhereverYouShop:
    'Nous appliquons automatiquement les coupons partout où vous faites vos achats.',
  continue: 'Continuer',
  changeLanguage: 'Changer la langue',
  selectedLanguage: 'Langue sélectionnée',
  headerMuteItem: 'Karma | Article silencieux',
  itemHasBeenMuted: "C'est fait ! Cet article a été mis en sourdine.",
  toUnmuteItem: 'Pour rétablir le son de cet article :',
  headToMyItems: "Allez dans l'onglet « Mes éléments »",
  clickThreeButtonsInItem: "Cliquez sur les trois points situés dans le coin inférieur droit de l'article.",
  clickUnmute: 'Cliquez sur « Rétablir le son »',
  weveJustRaisedInFunding:
    'Nous venons de lever {{value, number}} millions $ en série A pour vous aider à faire vos courses plus intelligemment !',
  weveJustRaised: 'Nous venons de lever {{value, number}} millions $',
  landingReview1: "Super application ! J'ai reçu une alerte de baisse de prix et j'ai économisé beaucoup d'argent !",
  landingReview2: "J'ai économisé {{value, number}} $ sur mon premier achat. Je suis très impressionné !",
  landingReview3: "J'adore cette application ; les notifications et les récompenses sont très utiles.",
  landingReview4: "Je n'ai jamais connu une expérience d'achat plus simple !",
  landingReview5: "J'ai économisé {{value, nombre}}% ! Je n'aurais pas été au courant de la promotion autrement !",
  landingReview6: 'Facile de suivre les produits que vous aimez... Hautement recommandé !',
  landingReviewAuthor1: 'Justin Nguyen',
  landingReviewAuthor2: 'Amy Ellis',
  landingReviewAuthor3: 'Maude Aethelredd',
  landingReviewAuthor4: 'Patrick Slocum',
  landingReviewAuthor5: 'Michelle Findley',
  landingReviewAuthor6: 'Varsha Hulekal',
  auxiliaryDate: '{{date}}',
  resetMyPassword: 'Réinitialiser mon mot de passe',
  repeatPassword: 'Répéter le mot de passe',
  continueAsGuest: 'Continuer comme invité',
  viewAt: 'Afficher à {{- name}}',
  signUp: 'Inscription',
  youMayAlsoLike: 'Vous pourriez aussi aimer',
  buyOnShop: 'Achetez sur',
  viewOn: 'Afficher à {{- name}}',
  quickView: 'Vue rapide',
  newList: 'Nouvelle liste',
  undo: 'Annuler',
  productDeleted: 'Produit supprimé',
  linkCopiedToClipboard: 'Copié dans le presse-papier!',
  wishlists: 'Préférences',
  headerPartnerships: 'Karma | Partenariats',
  letsJoinForces: 'Unissons nos forces',
  bePartOfTheSmartShoppingRevolution:
    'Participez à la révolution du shopping intelligent. Nous présenterons votre marque à plus de {{number, number}} millions d’acheteurs potentiels.',
  seeKarmaInAction: 'Voir Karma en action',
  discoverHowKarma:
    'Découvrez comment les solutions de Karma, basées sur l’IA et l’automatisation, améliorent le parcours d’achat complet et génèrent un trafic ciblé vers vos offres.',
  stepUpYourSales: 'Augmentez vos ventes',
  averageROAS: 'ROAS<br/>moyen',
  increaseInAOV: 'Augmentez<br/>le panier moyen',
  useKarmaToPlanTheirNextPurchase: 'Planifiez leur prochain<br/>achat avec Karma',
  takeYourPlaceInOurTargeted: 'Participez à nos newsletters ciblées et restez à l’avant-garde',
  meetOurShoppers: 'Rencontrez nos acheteurs',
  meetOurShoppersDescription:
    'Exploitez nos solutions marketing orientées performances et impressionnez les acheteurs à forte intention d’achat. Nos insertions multiplateformes vous permettent de contacter les utilisateurs partout.',
  audienceInfoFirstNumber: 'Plus de {{number, number}}{{unit}}',
  audienceInfoFirstTitle: 'Utilisateurs',
  audienceInfoSecondNumber: 'Plus de {{number, number}}{{unit}}',
  audienceInfoSecondTitle: 'Visites mensuelles',
  audienceInfoThirdNumber: 'Plus de {{number, number}}{{unit}}',
  audienceInfoThirdTitle: 'Articles sauvegardés',
  audienceInfoFourthNumber: '{{value, number}}%',
  audienceInfoFourthTitle: 'Utilisateurs aux États-Unis',
  audienceInfoFifthNumber: '{{value, number}}%',
  audienceInfoFifthTitle: 'Génération Z et milléniaux',
  audienceInfoSixthNumber: '{{value, number}}%',
  audienceInfoSixthTitle: 'Acheteuses',
  ourUsersLoveUs: 'Nos utilisateurs nous adorent!',
  partnershipsReview1:
    'Une extension géniale! Je l’utilise depuis des années,  c’est génial de pouvoir enregistrer beaucoup d’articles au même endroit - le shopping en ligne est vraiment moins stressant!',
  partnershipsReviewAuthor1: 'Millie Robinson',
  partnershipsReview2:
    'Comme je fais BEAUCOUP d’achats en ligne, je peux dire honnêtement que Karma est le meilleur ami des acheteurs pour de nombreuses raisons...',
  partnershipsReviewAuthor2: 'Sadie Mae',
  partnershipsReview3:
    'Meilleure application shopping dans Play Store. Très facile à utiliser. Tous mes articles sont enregistrés dans mes boutiques préférées sur une seule application!👍🏻',
  partnershipsReviewAuthor3: 'Kimberly Rogers',
  performanceBasis: 'Performance standard',
  fixedFee: 'Frais fixes',
  thanksForSubmitting: 'Merci pour l’envoi!',
  someoneFromOurPartnershipsTeamWillBeInTouchShortly:
    'Un membre de notre équipe de partenariat vous contactera prochainement.',
  letsKickStartOurPartnership: 'Lançons notre partenariat!',
  emailOrPhoneNumber: 'E-mail ou numéro de téléphone',
  retailerName: 'Distributeur identifié',
  affiliateNetwork: 'Réseau affilié',
  budgetStructure: 'Structure budgétaire',
  budget: 'Budget',
  tellUsMore: 'En dire plus',
  pleaseFillInAllRequiredFields: 'Veuillez remplir les champs obligatoires',
  partnerships: 'Partenariats',
  weCannotSaveThisItem: "Nous ne pouvons pas enregistrer cet article <1/> car cette boutique n'est pas prise en charge",
  theresCoupon: 'Il y a un bon de réduction !',
  yourItemIsOffWithCode:
    "Votre article bénéficie d'une réduction de {{value, number}}% avec le code {{code}} chez {{- name}}.",
  muteAllNotification: 'Désactiver toute notification',
  unMuteAllNotification: 'Activer toute notification',
  notificationSettings: 'Paramètre des notifications',
  deleteNotification: 'Supprimer la notification',
  notificationDeleted: 'Notification supprimée',
  itemMuted: 'Article masqué',
  itemUnMuted: 'Article démasqué',
  priceDrop: 'Baisse de prix',
  superDeal: '🔥 Excellente affaire',
  notSureWhenToBuy:
    "Vous ne savez pas quand acheter ? Créez un compte gratuit et nous vous dirons quand ce sera le moment d'acheter.",
  tcMayApply: "Les CGU peuvent s'appliquer",
  earnKarmaCash: "Gagnez jusqu'à {{percent, number}} % de Karma Cash.",
  saveToKarma: 'Sauvegarder le produit dans Karma',
  getKarmaToTrackItem: 'Demander à Karma de suivre le produit',
  itemSaved: 'Produit sauvegardé !',
  youCanNowFindItem: 'Vous retrouverez maintenant votre produit sur votre tableau de bord Karma.',
  getKarmaExtension: "Obtenez l'extension Karma pour <1/> suivre cet indispensable",
  notifyYou: "Nous vous avertirons quand ce sera le meilleur moment pour l'acheter !",
  downloadTheExtension: "Télécharger l'extension",
  outOfStock: 'Rupture de stock',
  invalidEmailError: 'E-mail invalide',
  collapse: 'Réduire',
  expand: 'Développer',
  lists: 'Listes',
  organizeYourItemsIntoWishListsWithJustAClick: "Organisez vos produits en listes de souhaits d'un simple clic.",
  newItem: 'Nouveau produit',
  helpCenter: "Centre d'aide",
  priceHighToLow: 'Du plus cher au moins cher',
  priceLowToHigh: 'Du moins cher au plus cher',
  sale: 'Promotion',
  filterBy: 'Filtrer par :',
  karmaExclusive: 'Exclusivité K',
  noItemsFound: 'Aucun produit trouvé',
  select: 'Sélectionner',
  likeSneakersOrBlackFriday: 'Comme « baskets » ou « Black Friday »',
  updateList: 'Actualiser la liste',
  firstName: 'Prénom',
  lastName: 'Nom',
  enterFirstName: 'Saisissez votre prénom',
  enterLastName: 'Saisissez votre nom',
  recentSearches: 'Recherches récentes',
  addYourFavoriteStores: 'Ajoutez vos magasins préférés',
  toAccessTheirLatestCoupons: 'pour accéder à leurs derniers coupons',
  logosProvidedByClearbit: 'Logos fournis par Clearbit',
  dateOfBirth: 'Date de naissance',
  weHaveEmailedYouALinkToResetYourPassword: 'Nous vous avons envoyé un lien pour réinitialiser votre mot de passe.',
  didNotGetIt: "Vous ne l'avez pas reçu ?",
  profile: 'Profil',
  promotionsAndActivity: 'Promotions et activités',
  couponsForFavoriteStores: 'Coupons pour les magasins préférés',
  accountActivity: 'Activité du compte',
  powerShopper: 'Consommateur averti ? 💪',
  enterLinkToAnyItemPage: "Saisissez un lien vers la page d'un produit",
  saveStuffWithZeroFuss: "<0>Obtenez l'extension de navigateur</0> et sauvegardez des trucs simplement.",
  viewMore: 'Afficher plus',
  tooHotToMiss: 'Trop bon pour passer à côté',

  // WebsiteLandingKarmaOld
  joinShoppers: "Rejoignez plus de {{number, number}} millions d'acheteurs",
  waysToUse: "Manière d'utiliser Karma",
  youCanUseKarma:
    'Vous pouvez utiliser Karma de nombreuses façons : par exemple, pour vous assurer que vous obtenez le meilleur prix au bon moment ou pour planifier vos prochains achats. Voici quelques exemples :',
  livesInBrowser: 'Karma habite dans votre navigateur',
  browserExtension: "L'extension de navigateur Karma est avec vous partout où vous faites des achats sur le web.",
  helpsYouPlan: 'Elle vous aide à planifier vos prochains achats',
  notReadyToBuyJust:
    'Pas encore prêt à acheter ? Sauvegardez le produit pour plus tard avec notre extension de navigateur et faites vos achats de manière plus réfléchie. Tous vos produits sauvegardés sont réunis en un seul endroit. ',
  automaticallyFindsCouponCodes: 'Trouve automatiquement des codes de coupon',
  findsAndAppliesCodes:
    "Karma trouve et applique automatiquement le meilleur coupon pour vous. Vous avez l'assurance de payer le meilleur prix à chaque fois.",
  soYouCanBuyAtTheRightTime: 'Pour acheter au bon moment',
  usesCleverTechnologies:
    "Karma utilise une technologie intelligente pour savoir si le prix baisse ou si le produit est réapprovisionné pour que vous puissiez l'acheter au bon moment. ",
  notOnlySave:
    "Non seulement Karma vous permet de dépenser moins d'argent, mais en plus vous en gagnez ! Pas de points. Rien de compliqué. Vous gagnez de l'argent réel chaque fois que vous faites des achats.",
  makeImpact: 'Changez le monde !',
  donate:
    "Faites vos achats comme d'habitude et regardez comment Karma continue à donner. Pour chaque achat de plus de {{over, number}} $, nous faisons un don de {{dollars, number}} $ à l'organisation caritative de votre choix.",
  accessToOffers: 'Accédez à des offres exclusives',
  accessItems:
    'Accédez à vos produits sauvegardés en un seul endroit, découvrez des offres exclusives et découvrez les tendances dans le monde entier sur Karma.',
  hereIsHowItWorks: 'Voilà comment ça marche',
  learnMoreAboutGives: 'En savoir plus sur Karma Gives',
  alwaysKnowWhenPricesDrop: 'Soyez toujours au courant des baisses de prix',
  getNotifiedWhenBackInStock: "Soyez informé du retour en stock d'un produit",
  findsYouBestCoupons: 'Trouvez les meilleurs coupons de réduction',
  getKarmaCashWhenYouShop: "Recevez de l'argent Karma en achetant",
  easilySaveAllThings:
    'Faites des économies facilement sur tous vos articles préférés dans plus de {{value, number}} mille magasins',
  karmaProvidesGreatTechnology:
    "Karma vous offre une technologie et une flexibilité exceptionnelles pour faire des achats plus intelligents, en vous aidant à éviter les achats impulsifs inutiles, tout en vous faisant gagner du temps et de l'argent.",

  accountActivityAndImportantInformation: 'Activité du compte et informations importantes',
  addKarmaToYourDesktop: 'Ajoutez Karma sur votre ordi pour passer commande<br/>pendant les heures de travail.',
  addNumberItemsToList: 'Ajouter {{value}} éléments à la liste',
  addOneItemToList: 'Ajouter un élément à la liste',
  addStuffYouFindToThisListToKeepItOrganized: 'Ajoutez tout ce que vous trouvez sur cette liste pour rester organisé.',
  alrightBut: "D'accord, mais vous risquez de le regretter !",
  areYouSureYouWantToDisconnectFromPaypal: 'Voulez-vous vraiment déconnecter PayPal ?',
  balance: 'Solde',
  buyWhateverYouWant: 'Achetez tout ce que vous voulez sans vous faire gronder par votre banquier.',
  cashIsBack: 'Le <1>cash</1> is back',
  cashIsBackColoredRight: 'Le cash <1>is back</1>',
  cashOutRewards: 'Encaisser les récompenses',
  checkItOffAsYouBuy: 'Retirez les articles que vous achetez',
  congratulationsOnCashout: 'Félicitations ! Vous pouvez maintenant encaisser vos récompenses.',
  connectToPayPalToCashOut: 'Connectez un compte PayPal pour encaisser vos récompenses',
  connectYourPaypalAccountToCashOutRewards: 'Connectez votre compte PayPal pour encaisser vos récompenses.',
  downloadOurApp: 'Téléchargez notre application et <1>emmenez Karma avec vous</1>',
  dropSomeShoppingInspo: "Saupoudrez un peu d'inspiration shopping sur votre famille.",
  earnKarmaCashShort: "Jusqu'à {{percent, number}} % K Cash",
  earned: 'Gagné',
  enterYourPassword: 'Saisissez votre mot de passe',
  firstAddToList: "Tout d'abord, ajoutez à la liste",
  followOnLinkedin: 'Suivez-nous sur LinkedIn',
  freshOutCoupons: 'Des coupons tout frais !',
  getBuzzedWhenYourWishes:
    'On vous buzzera 🐝 quand vos articles préférés baisseront de prix ou seront de nouveau en stock.',
  getIt: 'Installer',
  getTheApp: "Télécharger l'application",
  gotIt: "J'ai compris",
  gotStuck: 'Vous êtes coincé ? Cliquez <1>ici</1> pour avancer.',
  happyShoppers: '👉 {{count, number}} clients heureux',
  howElseCanWeImproveKarma: 'Sinon, comment améliorer Karma ?',
  iNeedThisInMyLife: "J'en ai besoin dans ma vie",
  info: 'Infos',
  itemUpdatesUnsubscribe: 'Infos sur les articles (baisses de prix, réapprovisionnements, etc.)',
  itsAGhostTown: "C'est une ville fantôme",
  joinTheTeam: "Rejoindre l'équipe",
  karmaGetsYouTheLowestPossiblePrice: 'Karma trouve toujours le prix le plus bas possible, à 👏 chaque 👏 fois.',
  karmaIsAnApp:
    "Karma est une application et une extension qui vous permet d'économiser du temps et de l'argent sur vos achats en ligne.",
  karmaYourFavoriteBrands: '<1>Karma</1> vos marques préférées',
  karmaYourFavoriteBrandsMobile: '<1>Karma</1> vos marques préférées',
  knowHowToShopBetter: 'Vous saurez comment <1>acheter mieux</1> que tout le monde',
  letsPlay: 'Jouons un peu',
  lolWereAVerbNow: '(MDR, « Karma » est un verbe, maintenant)',
  lookAtYouLittleScroller: 'Regarde-toi, petit scrolleur. Félicitations pour être allé aussi loin.',
  lookAtYouLittleScrollerMobile: 'Regarde-toi, petit scrolleur.',
  lookingForInfluencer: 'Vous cherchez des partenariats publicitaires ?',
  markItemsYouveMadeYourOwnItFulfillsSoGood:
    "Marquez les articles que vous vous êtes appropriés. C'est tellement bon !",
  markNumberItemsAsFullfilled: 'Vous êtes sur le point de marquer {{value}} éléments comme « trouvés »',
  meetYouAtIn: 'Rendez-vous chez {{retailer}} dans 3 secondes...',
  missOut: 'jamais rien',
  missingYouAlready: 'Vous nous manquez déjà !',
  money: "d'argent",
  never: 'Ne manquez',
  nevermind: 'Ne rien faire',
  noFomoHere:
    'Pas de blabla ici. Nous vous enverrons un buzz lorsque vos articles préférés seront à nouveau disponibles ou lorsque leur prix baissera.',
  noNotificationsYet: "On s'occupe de tout",
  noNotificationsYet2: "Dès qu'il y a une baisse de prix ou une remise en vente, vous le saurez ici.",
  nowLetsTalPrivacy: 'Parlons maintenant de <1>confidentialité<1/>',
  nowLetsTalkAboutPrivacy: 'Parlons maintenant de confidentialité 🤫',
  onlineShoppingButBetter: 'Des achats en ligne, mais <1>en mieux</1>',
  orCopyTheLinkBelowAndSend: "Ou copiez le lien ci-dessous et envoyez-le-leur à l'ancienne.",
  other: 'Autre',
  passwordValidationNote:
    'Doit comporter au moins 8 caractères, dont une lettre majuscule, une lettre minuscule et un chiffre.',
  payLess: 'Payer moins',
  playFavesWithYourItems: 'Choisissez vos chouchous parmi vos articles',
  preparingYourKarmaCash: 'Préparation de votre Karma Cash.',
  savingMoneyIsHotLetsGetOnThat: "Faire des économies, c'est tendance. Tout le monde est d'accord là-dessus ✌️",
  search: 'Recherche',
  seeHowToSave: 'Découvrez comment enregistrer',
  skipForNow: 'Ignorer pour le moment',
  soFarWeSavedYou: 'Grâce à nous, vous avez économisé',
  sorryForInconvenience: 'Désolé pour le désagrément, mais ça en vaut vraiment la peine.',
  starReviews: '👉 {{count, number}} 000 commentaires de 4,7 étoiles',
  starTheThingsYouNeedInYourLifeForQuickAccess:
    'Mettez en avant les trucs dont vous avez besoin le plus pour y accéder rapidement.',
  stillWannaUnsubscribe: 'Vous souhaitez quand même <1>vous désabonner de tous les e-mails de Karma</1> ?',
  storePromotionsAndOtherMarketingEmails: 'Promotions en boutique et autres e-mails marketing',
  storesWorldwide: '👉 {{count, number}} boutiques dans le monde entier',
  stuffWorthSavingGoesHere: 'Les trucs à enregistrer atterrissent ici 👇',
  sureYouWantToDeleteTheseNumberItems: 'Voulez-vous vraiment supprimer ces {{value}} éléments ?',
  sureYouWantToDeleteThisList: 'Voulez-vous vraiment supprimer cette liste ?',
  takeControlOfYourInbox: 'Prenez le contrôle de votre boîte de réception',
  theMotherOfAllWishLists: 'La patronne de toutes les <1>listes de souhaits<1/>',
  thisFieldIsRequired: 'Ce champ est obligatoire',
  toCashOutRewards: 'Pour encaisser vos récompenses, vous devez atteindre un minimum de {{minimum, number}} $.',
  toCashOutYouMustReach:
    'Pour encaisser vos récompenses, vous devez atteindre un minimum de {{value, number}} {{currency}}.',
  tryAgain: 'Réessayer',
  trySearchingForSomethingElse: 'Essayez de chercher autre chose.',
  updatePreferences: 'Modifier les préférences',
  wantIt: 'Je le veux',
  weAreBusySelfImproving: 'Nous sommes en train de nous améliorer',
  weAreVerySeriousAboutPrivacy:
    'Nous sommes très sérieux en matière de confidentialité et ne vendrons jamais vos données à des tiers. Jamais.',
  weDontSellYourData: 'Nous ne vendons pas vos données',
  weGotStuckOnOurWay: 'Nous sommes restés coincés en allant vers {{retailer}} !',
  weHitYouUp: 'Nous vous contacterons lorsque nous trouverons un code promo pour ce magasin.',
  weHustleForCoupons: 'Nous nous jetons sur les coupons',
  weUseBankLevelSecurity: 'La sécurité est de niveau bancaire',
  wellApplyCodesThatSaveYouMoney: 'Nous appliquerons des codes qui vous feront économiser des €€€.',
  wellTrackAndScanForCoupons:
    "Nous allons rechercher et scanner les coupons pour que vous n'ayez rien à faire. <br/>(Bon d'accord, ce n'est pas vraiment nous. C'est notre IA)",
  wellTrackAndScanForCouponsMobile: 'Nous rechercherons les coupons à votre place.',
  wereAVerbNowLol: '(Nous sommes devenus un verbe, maintenant. MDR)',
  wereGoingShopping: 'On va faire du shopping',
  wereHereToMakeItYours: 'Nous sommes là pour <1>#make_it_yours</1>',
  whyUnsubscribeFromAllOurMoneySavingEmails:
    'Pourquoi vous désabonner de tous nos e-mails qui vous font faire des économies, alors que vous pouvez désactiver uniquement ceux qui ne sont pas pertinents ?',
  withKarmaYouCanBuy:
    'Avec Karma, vous pouvez acheter<br />tout ce que vous voulez sans vous faire<br />gronder par votre banquier.',
  withKarmaYourCash: "Avec Karma, votre argent revient comme si c'était du vaudou, une sorte d'argent magique.",
  yesDisconnect: 'Oui, déconnecter',
  yesIDoThat: 'Oui, je le fais',
  youHaveToReconnectToWithdraw: 'Vous devrez vous reconnecter pour retirer votre Karma Cash.',
  youNeedToSaveItemsBeforeYouCanShareYourList:
    'Vous devez enregistrer des éléments avant de pouvoir partager votre liste.',
  youReATen:
    'Vous êtes un expert et vous payez avec Karma ? Achetez en ligne et profitez des prix les plus bas 👏 chaque 👏 fois.',
  youReAboutToMuteNumberItems: 'Vous êtes sur le point de désactiver les notifications pour {{value}} éléments',
  yourCashWillComeBackToYou: "Votre argent revient comme si c'était du vaudou, une sorte d'argent magique. ",
  yourInformationIsSecured:
    'Vos informations sont sécurisées avec la dernière technologie de cryptage 256 bits pour une protection intégrale.',
  yourSearchCameUpEmpty: 'Votre recherche est vide',
  total: 'Total',
  keepAnEyeOutForTheStatusUpdatesWeWillSendToEmail:
    'Surveillez les mises à jour de statut que nous enverrons à {{email}}.',
  psaDidYouKnow: 'Saviez-vous que vous pouvez',
  addWishItemsFromAnyBrand:
    'Ajoutez des articles à votre liste de souhaits et nous vous indiquerons le meilleur moment pour les acheter.',
  capturePurchaseReadyShoppers: 'Attirez les clients prêts à acheter en présentant vos meilleurs coupons.',
  gainFullFunnelInsights: 'Découvrez l’intention de l’acheteur pour chaque article enregistré.',
  boostCartEngagement: "Boostez l'engagement du panier avec nos mises à jour d'articles en temps réel.",
  promoteLongTermGrowth:
    "Promouvez votre croissance à long terme grâce à des campagnes personnalisées au sein de l'écosystème Karma.",
  reachNewAudiences: 'Atteignez de nouveaux publics grâce à diverses campagnes adaptées à votre marque',
  influencerCollaborations: "Collaborations d'influenceurs",
  teamUpWithTopInfluences:
    "Faites équipe avec les meilleurs influenceurs qui correspondent le mieux à l'identité de votre marque.",
  socialPosts: 'Réseaux sociaux',
  giveANewFollowing: 'Obtenez de nouveaux adeptes avec des campagnes personnalisées riches en contenu.',
  brandFocusedNewsletters: 'Newsletters axées sur la marque',
  letYourOffersShine: 'Mettez vos offres en valeur dans des e-mails très soignés.',
  driveTrafficWhenYouNeedIt: "Stimulez le trafic là où vous en avez besoin grâce à nos emplacements sur page d'accueil",
  dashboardBanners: 'Bannières publicitaires',
  flauntYourLatestOfferings: 'Affichez vos dernières offres avec des visuels accrocheurs.',
  dailyFeatured: 'Mise à jour quotidienne',
  highlightDeals: 'Mettez en avant les offres là où elles comptent le plus.',
  dedicatedPopUps: 'Messages pop-ups dédiés',
  activelyServeYourPromos: 'Servez activement vos promotions afin que personne ne les manque.',
  createAnotherTouchpointForYourBrand: 'Créez un autre point de contact pour votre marque',
  increaseEngagement:
    "Augmentez les taux d'engagement et de conversion grâce à des notifications push ciblées sur mobile et sur le Web.",
  karmaIsOnlineShoppingButBetter: "Karma, c'est du shopping en ligne, mais mieux.",
  transactionsNumber: '👉 Plus de {{count, number}} millions de transactions',
  partnershipsReview4: "C'est fantastique pour faire une liste de souhaits et économiser de l'argent !",
  partnershipsReviewAuthor4: 'Emily Smith',
  partnershipsReview5:
    "C'est littéralement la meilleure extension que j'ai jamais téléchargée. C'est le mieux pour économiser de l'argent et obtenir de l'argent en même temps !",
  partnershipsReviewAuthor5: 'Selena Roblox',
  noNeedToSlideIntoOurDMs:
    'Pas besoin de nous envoyer de message privé (même si vous pouvez 😏). Notre équipe est là pour tout ce dont vous avez besoin.',
  supportIssues: "Problèmes d'assistance",
  adsAndPartnerships: 'Pubs et partenariats',
  jobOpportunities: 'Opportunités de carrière',
  publicRelations: 'Relations publiques',
  letsChat: 'Discutons',
  earnings: 'Revenus',
  allTime: 'Tout',
  ordersMayTakeHoursToAppear: "Les commandes peuvent prendre jusqu'à {{value, number}} heures avant d'apparaître.",
  youHaventReceivedAnyPayoutsYet: "Vous n'avez pas encore reçu de versements ",
  alerts: 'Alertes',
  featuresReview1:
    "J'ai ajouté une paire de baskets à ma liste de souhaits et le lendemain, ils m'ont notifié l'existence d'une réduction de 50 %. Comment ai-je pu acheter quoique ce soit avant vous ?",
  featuresReviewAuthor1: 'Sandra C.Martinez, Évaluatrice App Store',
  featuresReview2:
    "C'est vraiment la meilleure application de shopping intelligent, et j'en connais beaucoup. En fait, j'ai l'impression de profiter un peu du système, parce que j'économise de l'argent réel ! J'avais l'habitude de payer le prix fort, comme un pigeon",
  featuresReviewAuthor2: 'Gertie D. Ackerman, Évaluatrice App Store',
  featuresReview3:
    "Je cherchais un nouvel ordinateur portable qui était déjà à - 25 %. Quand j'ai cliqué sur « Payer avec Karma », j'ai remarqué que le total était encore moins élevé. Cela se produit à chaque fois que je paie avec l'application",
  featuresReviewAuthor3: 'Irene J. Cox, Évaluatrice App Store',
  downloadNow: 'Télécharger maintenant',
  wannaBeDeskBuddies: 'Vous voulez rejoindre notre équipe ?',
  seeOpenPositions: 'Postes à pourvoir',
  weLiveAndSwearBy: "Pour nous, le plus important c'est...",
  goodKarma: 'Un bon karma',
  weBelieveThatGoodVibes: 'Nous croyons que les bonnes vibrations sont un mode de vie et nous nous y tenons.',
  beingTrue: "L'authenticité",
  theresNoUSvsU: 'Nous sommes à vos côtés. Nos tables sont toujours rondes et une place vous y attend.',
  passion: 'La passion',
  weHaveThatFireYouKnow:
    "Vous voyez, nous avons cette énergie ! Nous travaillons en nous appuyant sur la motivation, la persévérance et l'amour pour ce que nous faisons.",
  entrepreneurship: 'Entrepreneuriat',
  weWantItWeGetIt:
    "Nous voulons quelque chose, nous l'obtenons. On n'a pas de temps à perdre en réunions Zoom interminables. Nous allons droit au but.",
  mutuality: 'Mutualité',
  wereMadeUpOfAwesomePeople:
    "Notre équipe est composée de gens formidables qui comprennent que le travail d'équipe ne suffit pas. Nous nous soucions vraiment les uns des autres.",
  buildCustomerLoyalty: "Récompensez la fidélité des clients avec de l'argent en espèces",
  itemSaving: "Sauvegarde de l'article ",
  itemUpdates: 'Mises à jour des articles',
  backInStockAlerts: 'De nouveau en stock',
  lowInStockAlerts: 'Stock faible',
  storePromotions: 'Promotions de la boutique',
  exclusive: 'exclusif',
  couponCopied: 'Coupon copié',
  completedDesc:
    "La boutique a confirmé votre commande. Tout Karma Cash gagné peut être retiré {{days, number}} jours à compter de la date d'achat et seulement après avoir atteint le minimum de {{currency}} {{amount, number}}.",
  notQualifiedDesc: "Des restrictions supplémentaires peuvent s'appliquer aux articles que vous avez commandés.",
  pendingDesc:
    'La boutique a bien reçu votre commande. Elle peut être affichée comme étant en cours pendant {{days, number}} jours, en fonction de la politique de retour de la boutique.',
  cancelledDesc: 'La boutique nous a informés que votre commande a été annulée ou entièrement remboursée.',
  downloadNowItsFree: "Téléchargez maintenant, c'est gratuit",
  weLiveToMakeOurShoppersHappy: 'Nous vivons pour rendre les acheteurs heureux',
  moneySavedForYou: "De l'argent économisé",
  happyShoppers2: 'Des acheteurs heureux',
  starReviews2: 'Avis avec {{value, number}} étoiles',
  storesWorldwide2: 'Des boutiques partout dans le monde',
  whyYouWill: 'Pourquoi vous allez',
  it: 'adorer',
  multiBrandWishLists:
    "Des listes de souhaits multi-marques ? De vrais frissons. Trouvez un article de n'importe quelle marque que vous aimez, ajoutez-le à votre liste Karma et attendez que nous vous disions quand c'est le bon moment pour l'acheter.",
  addKarmaToYourMobile: 'Téléchargez<br /> notre application<br /> et emmenez<br /> Karma partout avec vous',
  appStoreReview1:
    "J'ai ajouté une palette de fards à paupières à ma liste et le lendemain j'ai reçu une notification indiquant qu'elle était à - 50 %. Merci !!",
  appStoreReview2:
    "Le fait que le prix le plus bas soit toujours à ma portée est tellement dangereux. Mais j'aime aussi cela",
  appStoreReview3:
    "Je viens d'obtenir le prix le plus bas sur de nouveaux écouteurs tout en attendant le bus. C'est trop facile.",
  appStoreReviewBy: "Avis sur l'App Store par : {{name}}",
  yourInfoSafeWithUs: 'Vos informations sont en sécurité avec nous',
  whyGiveOutYourCCDeets:
    'Pourquoi donner sans cesse les infos de votre carte alors que vous pouvez les garder à un seul endroit ?',
  partyForOne: 'Pour vous',
  wellNeverSellYourDataToAnyThirdParties:
    "Nous ne vendrons jamais vos données à des tiers, point. Avec un niveau de sécurité des données égal à celui de la banque, vos données sont à l'abri des regards indiscrets.",
  buyWithoutATrace: 'Acheter sans laisser de trace',
  weWontLetAnyoneTrackYourShoppingHabits:
    "Nous ne laisserons personne suivre vos habitudes d'achat. Il n'y a pas de jugement ici.",
  spotSomethingYouLoveFromAnyBrand:
    "Trouvez un article de n'importe quelle marque que vous aimez, ajoutez-le à votre liste Karma et attendez que nous vous disions quand c'est le bon moment pour l'acheter.",
  needIt: "J'en ai besoin",
  appStoreReviewer: 'Évaluatrice App Store',
  appStoreReview4:
    'Je compte tellement sur cette application pour garder une trace de tous mes articles « désirés » au même endroit pour les obtenir au meilleur prix possible.',
  appStoreReview5: "Cette application m'a aidée à économiser tellement d'argent.",
  wishlist: 'Liste de souhaits',
  getTheLowestPriceAtAllYourFavoriteBrands: 'Obtenez les prix les plus bas pour toutes vos marques préférées.',
  getTheAppForFree: "Obtenez l'application gratuitement",
  addKarmaSpaced:
    'Ajoutez Karma <br />sur le bureau de votre ordi<br /> pour passer commande<br /> pendant les heures de travail.',
  spotSomethingYouLoveFromAnywhere:
    "Trouvez un article que vous aimez n'importe où, ajoutez-le à votre liste Karma et attendez que nous vous disions quand c'est le bon moment pour l'acheter.",
  getCouponsCashbackWithPeriod:
    'Recevez des coupons, des remises en espèces et des alertes en cas de baisse de prix.<br /> Payez toujours le prix le plus bas.',
  getCouponsCashbackWithAnd:
    'Obtenez des coupons, des remises en espèces, des alertes en cas <br />de baisse de prix et payez toujours le prix le plus bas.',
  clickTheLoginLinkFromYourPhone:
    "Cliquez sur le lien de connexion de votre téléphone pour accéder à l'application Karma.",
  getItOn: 'Installez-la',
  downloadOnThe: 'Télécharger sur',
  likeShoppingFromYourComputer: 'Vous aimez faire du shopping depuis votre ordinateur ?',
  getTheKarmaExtensionToShopEvenBetter: "Utilisez l'extension Karma pour faire du shopping d'une meilleure manière.",
  headerMobileLogin: 'Karma | Installer',
  noFomoHereMobile:
    'Ici, vous ne ratez rien. Nous envoyons un message quand vos « souhaits » sont disponibles ou lorsque leur prix baisse.',
  withKarmaYourCashMobile: 'Avec Karma, votre argent vous revient comme par magie.',
  spotSomethigYouLoveMobile:
    "Trouvez un article que vous aimez n'importe où, ajoutez-le à votre liste Karma et attendez que nous vous disions quand c'est le bon moment pour l'acheter.",
  wellTrackAndScanForCouponsMobile2:
    "Nous suivons et scannons les coupons pour que vous n'ayez rien à faire. (Bon d'accord, ce n'est pas vraiment nous. C'est notre IA)",
  weHustle: 'Nous nous activons',
  forCoupons: 'pour <1>des coupons</1>',
  theMotherOfAll: 'La meilleure des',
  wishlists2: 'listes de souhaits',

  // NEW PHRASES
  moneySavedForUsers: 'Money saved for users',
  whyYouLoveMerchantsTitleDesktop3: '<1>One-swipe<1/> checkout is yours',
  whatItsLikeWorkingAtKarma2: 'What’s it like working at Karma?',
  makeItYours: 'Make it yours',
  continueWith: 'Continue with {{provider}}',
  onItsWay: 'On its way...',
  weVeEmailedInstructionsForResettingYourPassword:
    'We’ve emailed instructions for resetting your password to {{email}}',
  wrongEmailTryAgain: 'Wrong email? Try again',
  goToEmail: 'Go to email',
  heyYouLetsJumpBackIntoSaving: 'Hey you! Let’s jump back into saving',
  firstConfirmItsYou: 'First, confirm it’s you',
  letsDoThis: 'Let’s do this',
  signUpAndGet: 'Sign up and get',
  moneyOnUs: '{{currency}}{{value, number}} on us',
  usersHeroTitle: 'Your\nbank account’s gonna love this.',
  tellMeMore: 'Tell me more',
  createNewPassword: 'Create new password',
  joinKarma: 'Join Karma',
  whyYouLoveMerchantsSubtitle3: 'Pay with Karma works with all retail platforms, with zero\nintegration and no fees.',
  saveItemsFromStores: 'Save items from your favorite stores with a single click',
  easilySaveAndKeep:
    'Easily save and keep track of everything you want to buy. The best\npart? You’ll get notified the second any of your saved items\ngo on sale, or come back in stock.',
  addTheFreeKarma:
    "Add the free Karma button to your browser so you can\neasily keep track of everything you want to buy. The best\npart? You'll get notified the second any of your saved items\ngo on sale, drop in price, or come back in stock.",
  getButtonAndGetEverything: 'Get the free Karma button and\nget everything you want on sale',
  getKarmaItIsFree: "Get Karma - It's Free!",
  onYourPhone: 'On your phone? Save items on the go with our free app',
  hereHowTheButtonWorks: "Here's how the Karma\nbutton works",
  dontSellMyPersonalInformation: 'Don’t sell my personal information',
  postCode: 'Postcode',
  ifThisWontHelp: "If this won't help, ",
  contactSupport: 'contact our support center.',
  wellKeepAnEyeOnIt: 'We’ll keep an eye on it & will let u know ASAP if the price drops',
  seeYourSavedItems: 'See your saved items',
  logInAndSaveItemToKarma: 'Login & Save item to Karma',
  blogPromotions: 'Blog promotions',
  expiringIn: 'Expiring in',
  goToStore: 'Go to store',
  visitStorePage: 'Visit store page',
  favStoresUpdated: 'Your favorite stores were successfully updated.',
  availableCoupons_plural: '{{count}} available coupons',
  availableCoupons: '{{count}} available coupon',
  findAndComparedTheBestDeals: 'Finds and compares the best deals',
  karmaHelpsYouShopSmarter: 'Karma helps you shop smarter by finding the best benefits and rewards at checkout',
  expiring: 'Expiring',
  today: 'Today',
  tomorrow: 'Tomorrow',
  days: '{{count, number}} DAY',
  weeks: '{{count, number}} WEEK',
  months: '{{count, number}} MONTH',
  daysPlural: '{{count, number}} DAYS',
  weeksPlural: '{{count, number}} WEEKS',
  monthsPlural: '{{count, number}} MONTHS',
  tellUsWhyYouUninstalledTheExtension: 'Tell us why you uninstalled the extension',
  tooManyPopUpsWhileIShop: 'Too many pop-ups while I shop',
  couponsDontWork: 'Coupons don’t work',
  dataPrivacyConcerns: 'Data privacy concerns',
  causedProblemsWithMyBrowser: 'Caused problems with my browser',
  tellUsMoreOptional: 'Tell us more (optional):',
  shareYourThoughtsWithUs: 'Share your thoughts with us',
  myFavoriteStoresAreNotSupported: 'My favorite stores are not supported',
  notReadyToLetGoYet: 'Not ready to let go yet? Reinstall Karma with just one click',
  chatWithUsAndGet: 'Chat with us & get<br />${{count}} Amazon Gift Card',
  scheduleAMeetingAndEarnGiftCard:
    'Schedule a {{minutes}}-minute interview and earn a<br />${{count}} Amazon gift card.',
  scheduleAnInterview: 'Schedule an interview',
  compareProductsBeforeYouBuy: 'Compare products before you buy',
  downloadWithKarmaAndSaveMajorCash: 'Download Karma<br/>and save major $$$',
  youSearchedFor: 'You searched for',
  yourSavedItems: 'Your saved items',
  moreForYou: 'More for you',
  headerGlobalSearch: 'Karma | {{query}}',
  noResultsFoundFor: 'No results found for',
  pleaseReviewYourSpellingOrTryDifferentKeyword: 'Please review your spelling or try different keyword',
  heyThere: 'Hey, {{name}}',
  myAccount: 'My account',
  accountSettings: 'Account settings',
  trendingNow: 'Trending now',
  helpUsImproveKarma: 'Help us improve Karma',
  yourFavoriteStores: 'Your favorite stores',
  chooseYourFavoriteStores: 'Choose your favorite stores',
  viewStoreInfo: 'View store info',
  addStore: 'Add store',
  findMoreStores: 'Find more stores',
  whatAreYouShoppingFor: 'What are you shopping for?',
  searchForAnyItemBrandOrStore: 'Search for any item, brand or store',
  savedFromStore: 'Saved from store',
  manageFavoriteStores: 'Manage favorite stores',
  hasNoActiveCoupons: '{{retailer}} has no active coupons',
  checkOutOtherStores: 'Check out other stores for more deals',
  youCanStillEarn: 'You can still earn {{number}}% cashback with Karma!',
  retailerCoupons: '{{retailer}} coupons',
  nothingSavedYet: 'Nothing saved yet?',
  useOurBrowserExtension:
    'Use our browser extension on your {{retailerName}} visit to quickly add items to your favorites. Your shopping journey starts here!',
  letsMakeItPersonal: 'Let’s make it personal',
  selectAndTrack: 'Select your favorite stores and track coupons and deals',
  chooseYourFaves: 'Choose your faves',
  code: 'Code',
  itemFound: '{{count, number}} item found',
  itemFound_plural: '{{count, number}} items found',
  storeFound: '{{count, number}} store found',
  storeFound_plural: '{{count, number}} stores found',
  itemsTitle: 'Items',
  storeInfo: 'Store info',
  preOwned: 'Pre-owned',
  refurbished: 'Refurbished',
  favorited: 'Favorited',
  allPromotionsOrOffers:
    "All promotions or offers by any of our partners are subject to the applicable partner's terms and conditions, which may be changed by the partner at its discretion. We may receive a commission for purchases made through our links or using our coupons. Eligibility for a welcome bonus is subject to section 5 of Karma’s Terms & Conditions.",
  shippingInfo: 'Shipping info.',
  freeShipping: 'Free shipping',
  overMinAmount: 'over {{currency}}{{value, number(minimumFractionDigits: 2)}}',
  delivers: 'Delivers',
  deliveryDays: '{{value, number}} days',
  freeReturns: 'Free returns',
  upToDays: 'up to {{value, number}} days',
  deliveryInfo: 'Delivery info.',
  returnsInfo: 'Returns info.',
  notAvailable: 'Not available',
  minOrderMayApply: 'Min. order may apply',
  hottestDeals: 'Hottest deals',
  discoverCouponsAndExclusiveOffers: 'Discover coupons and exclusive offers',
  moreFromStore: 'More from {{store}}',
  seeWhatsTrendingNow: 'See what’s trending now',
  automaticallyApplyCoupons:
    'Automatically apply coupons at checkout, save what you love and earn cashback — all with the Karma extension.',
  deleteMyAccount: 'Delete my account',
  areYouSureDeleteAccount: 'Are you sure you want to delete your account?',
  youHaveKarmaCash: 'You have {{currency}}{{value, number}} Karma Cash in your wallet.',
  deletingYourAccountWill:
    'Deleting your account will permanently remove all of your information, your saved items and your earnings from our database, and this action cannot be undone.',
  cancel: 'Cancel',
  accountDeletedSuccessfully: 'Account deleted successfully',
  shopOnTheGo: 'Shop on-the-go with Karma app',
  moveFromDevice: 'With Karma, effortlessly move from one device to another as you shop.',
  fewMomentsWeAreStillWorkingOnSavingItemsToThisList:
    'Few moments, we’re still working on saving items to this list... ',
  notificationDate: '{{date, shortFromNowDate}} ago',
  onboardingIOSTitle: 'Enable Karma on Safari \n to access our coupon scanner',
  enableKarmaOn: 'Enable Karma on Safari',
  toAccessOurCouponScanner: 'to access our coupon scanner',
  dontSeeReview: 'I don’t see a “Review” button',
  iosOnboardingFlowSubtitle: '{{step, number}}/{{numberOfSteps, number}} ',
  tapExtensionThenSelect: 'extension, then <1>select both middle options</1>',
  clickInTheAddressBar: 'Click<1></1>or',
  activate: 'Activate',
  grantAccess: 'Grant access',
  sweOnboardingStep1_1_1: 'Tap <1></1> or',
  sweOnboardingStep1_1_2: 'in the address bar',
  sweOnboardingStepAlt1_1: 'Tap the aA in the address bar',
  sweOnboardingStep1_2: 'Select <1>“Manage Extensions”<1/>',
  sweOnboardingStep1_3: 'Turn on <1>“Karma”</1>',
  sweOnboardingStep2_1_old: 'Tap the <1>A</1><2>A</2> in the address bar again',
  sweOnboardingStep2_2_old:
    'Tap the Karma extension <1></1>,<3></3> and select <5>“Always Allow...”</5>, then <7></7><8>“Always Allow on Every Website”</8>',
  sweOnboardingStep2_3_old: 'Select “Always allow”',
  sweOnboardingStep2_4_old_1: 'Select “Always allow”',
  sweOnboardingStep2_4_old_2: '“On every Site”',

  sweOnboardingStep2_5_old_1: 'Go back to the',
  sweOnboardingStep2_5_old_2: 'Karma app',
  sweOnboardingStep2_1: 'Tap <1>“Review”</1> in the top right corner, then <3>select both middle options</3>',
  shopSmarter: 'Shop better with\nthe power of AI',
  joinOurUsers: 'Join our {{number, number}} million users\nwho are shopping\nbetter from all their\ndevices',
  browseAndBuy:
    'Browse and buy from our desktop extension or\nuse the Karma app to ensure you’re always\ngetting the most out of your purchase.',
  joinCard1: '<0>{{value}}M+</0><1>shoppers</1>',
  joinCard2: '<0>15K</0><1>5 star reviews</1>',
  joinCard3: '<0>$5 cash</0><1>at sign-up</1>',
  joinCard4: '<0>2.3 million</0><1>mobile app users</1>',
  joinCard5: '<0>$7.50</0><1>avg order savings</1>',
  joinCard6: '<0>50 million</0><1>items tracked</1>',
  whatMakesKarmaGood: 'What makes Karma\nso good?',
  karmaGoodTitle1: 'Karma let’s you shop\nbetter from anywhere',
  karmaGoodDescription1:
    'Karma’s browser extension meets you\nwherever you shop. Shop directly in the Karma\napp or share the items you want to Karma.',
  karmaGoodTitle2: 'Helps you plan your\nnext purchases',
  karmaGoodDescription2:
    'Not ready to buy just yet? Save it for later in the\nKarma app or share it to Karma and shop more\nmindfully. You can keep an eye on all your\nsaved items in one place.',
  karmaGoodTitle3: 'Finds and compares the\nbest deals',
  karmaGoodDescription3: 'Karma helps you shop better by instantly\ncomparing products from different retailers.',
  karmaGoodTitle4: 'So you can buy at the\nright time',
  karmaGoodDescription4:
    "Karma uses clever technology to track if the\nprice drops, or when it's back in stock, so you\ncan buy at the right time.",
  onlyOnMobile: 'Only on the mobile app',
  empowerTouToMake: 'Empower you to\nmake the right\nchoices at the right\ntime when you shop\nonline.',
  dontTakeOurWord: 'Don’t take our word for it',
  totalSavings: 'Total savings',
  joinOurUsersDesktop: 'Join our {{number, number}} million users who are\nshopping better from all their devices',
  andAutomaticallyFindsYouCouponsAtCheckout: 'And automatically finds you coupons at checkout',
  clickAlwaysAllowOnEveryWebsite: 'Click <2>“Always Allow on Every Website“</2> so we can work on all your fave sites',
  weNeedYourPermissionToAutomaticallyShowYouTheBestDeals:
    'We need your permission to automatically show you the best deals on all your favorite shopping websites.',
  yourPrivacyAndSecurityIsImportantToUs: 'Your privacy and security are important to us - it’s in our',
  whileKarmaWillStillWork: 'While Karma will still work, it’ll require more effort on your part to find deals.',
  unfortunatelyWithoutTheAlwaysAllowPermission:
    'Unfortunately, without the “Always Allow on Every Website” permission you’ll have to manually click Karma whenever you shop',
  noThanksSignUpForAnAccount: 'No thanks, Sign up for an account',
  logInWithEmail: 'Log in with email',
  signUpWithEmail: 'Sign up with email',
  iAgreeToTermsOfUseAndPrivacyPolicy: 'I agree to <1>Terms of Use</1> and <3>Privacy Policy</3>',
  joinOurEnergeticTeam: 'Join Our Energetic Team',
  atKarmaWeReNotJustAboutWork:
    'At Karma, we’re not just about work; we’re about creating an environment where everyone’s input is valued and where each day brings a new opportunity for growth and impact.',
  beAPartOfTheJourney: 'Be a part of the journey',
  noPositionsAvailable: 'No positions available',
  apply: 'Apply',
  dontSeeAnythingThatFitsYourSkillSet:
    "Don't see anything that fits your skill set? Don't let that stop you from reaching out! If you think you can help us grow, send an email to",
  ifYouReReadyToMakeAnImpact:
    'If you’re ready to make an impact and thrive in a setting that values speed, fun, and collaboration, Karma might be your next great adventure.',
  exploreOurCareerOpportunities:
    'Explore our career opportunities and find out how you can contribute to our exciting journey ahead. Join us, and let’s achieve greatness together!',
  welcomeToKarma:
    'Welcome to Karma—where technology meets savvy shopping. Join us in our mission to revolutionize the way you shop, ensuring every purchase is just right for you.',
  empoweringSmartShopping: 'Empowering Smart Shopping:\nThe Karma Story',
  ourMissionPart1:
    'At Karma, we believe in empowering shoppers to make wise and informed purchasing decisions. Founded in 2015, our mission has always been to guide consumers toward smarter, more responsible shopping. As both a browser extension and mobile app, Karma harnesses the power of advanced AI to enhance your shopping experience. This technology predicts the optimal time to make a purchase, ensuring you get the best deal possible.',
  ourMissionPart2:
    'Over the years, Karma has continuously evolved, integrating cutting-edge technology to keep pace with the dynamic e-commerce landscape. We take pride in helping our users avoid buyer’s remorse and overspending by providing them with the tools they need to shop confidently!',
  hearFromOurUsers: 'Hear from our users',
  karmaPatents: 'Karma patents',
  systemAndMethodForPreauthorizing: 'System and method for preauthorizing database transaction commits',
  systemAndMethodForScraping: 'System and method for near real time web scraping',
  startYourShoppingJourney: 'Start your shopping journey',
  searchForItemBrandStore: 'Search for an item, brand or store',
  quillDisclosure: 'HP, Epson, & Samsung excluded',
  theKButtonIsInMyWay: 'The K button is in my way',
  itWasntHelpful: 'It wasn’t helpful',
  reinstallTheExtension: 'Reinstall the extension',
  tooManyCouponPopupsAtCheckout: 'Too many coupon pop-ups at checkout',
  didYouKnowYouCanHideTheKButtonAndDisablePopupsFromYourSettings:
    'Did you know you can <1>hide</1> the K button and <3>disable</3> pop-ups from your settings?',
  cookies:
    'If you accept cookies, we’ll use them to improve and customize your experience and enable our partners to show you personalized ads when you visit other sites. <2>Manage cookies and learn more</2>',
  decline: 'Decline',
  confirmYourCookieSettings: 'Confirm your cookie settings',
  youControlHowWeUseCookies:
    'You get to control how we use cookies on each device and browser you use. These settings will apply to your current device when you use {{browser}}.',
  whatAreCookies: 'What are cookies?',
  cookiesAndTrackingTechnologies:
    'Cookies and tracking technologies are small text files stored on your device when you use a web browser. Some cookies are essential for you to use our site, while other cookies collect data about your browsing habits. We use this data to give you the best experience.',
  marketing: 'Marketing',
  weUseMarketingCookies: "We use marketing cookies to deliver ads we think you'll like.",
  marketingCookiesLet: 'For example, marketing cookies let us show you personalized ads based on your interests.',
  performance: 'Performance',
  weUsePerformanceCookies: 'We use performance cookies to understand how you interact with our site.',
  performanceCookiesHelp:
    'For example, performance cookies help us learn which parts are the most popular and which parts we could improve for you.',
  functional: 'Functional',
  weUseFunctionalCookies: 'We use functional cookies to customize your experience.',
  functionalCookiesLet:
    'For example, functional cookies let us remember your preferences like language, country or region, and text sizes.',
  essential: 'Essential',
  weUseEssentialCookies: 'We use essential cookies to make our site work for you.',
  essentialCookiesLet:
    'For example, essential cookies let you securely sign in and browse our site. These cookies help us keep your account safe and prevent fraud.',
  youCanReadMoreAboutCookies: 'You can read more about how we use cookies in our <2>Cookie Statement</2>.',
  confirmCookieSettings: 'Confirm Cookie Settings',
  noMinimumOrderValue: 'No minimum order value',
  fromToDays: '{{value, number}} to {{value2, number}} days',
  headerLogin: 'Karma | Login',
  headerOnboarding: 'Karma | Onboarding',
  yourDataHasBeenRemoved: 'Your data has been successfully removed.',
  confirmationCode: 'Confirmation code: {{code}}',
  startOnlineShopping: 'Start online shopping to see Karma in action',
  trendingOffers: 'Trending Offers',
  useCode: 'Use code: {{code}}',
  getDeal: 'Get Deal',
  buyNow: 'Buy now',
  trendingCoupons: 'Trending Coupons',
  searchResultsFor: 'Search Results for "{{search}}"',
  trendingItems: 'Trending Items',
  curateSmartWishlists: 'Curate Smart wishlists',
  karmaWillLetYouKnow: 'Karma will let you know when the items you want go on sale or are back in stock',
  saveEarnAtCheckout: 'Save & Earn at checkout',
  weApplyBestCoupon:
    'We’ll automatically apply the best coupon codes on the web at checkout & reward for your purchase on 1000’s of stores',
  welcomeTo: 'Welcome to',
  youAreOneStepCloser:
    'You’re one step closer to saving money\nand shopping smarter.\nGo to your favorite store and we’ll see you there!',
  startBrowsing: 'Start browsing',
  scanToGetApp: 'Scan to get the app',
  unlockYourCreditCardsBenefits: 'Unlock your credit cards benefits',
  easilyCompareBetweenCreditCards:
    'Easily compare between the credit cards rewards to choose the best one to use at checkout',
  emailPositiveFeedbackThankYou: 'Thank you for your feedback!',
  emailPositiveFeedbackYourExperience: 'Share your experience by leaving a review on your platform of choice:',
  headerPositiveFeedback: 'Karma | Email Positive Feedback',
  headerNegativeFeedback: 'Karma | Email Negative Feedback',
  emailNegativeFeedbackTitle: 'We’re listening, share your feedback',
  emailNegativeFeedbackSubTitle:
    'To help us resolve the issue faster, please let us know what’s wrong with the notification',
  emailPriceDoesNotMatch: 'The email price does not match the website price',
  emailItemNotOnSale: 'The item is not on sale',
  emailItemOutOfStock: 'The item is out of stock',
  emailLinkNotWorking: 'The link is not working',
  emailPromoCodeNotAccepted: 'Promo code is not accepted by store',
  emailPromoCodeOnPage: 'Couldn’t apply the promo codes on the page',
  emailOther: 'Other',
  headerNegativeFeedbackSuccess: 'Karma | Negative Feedback Success',
  emailNegativeFeedbackSuccessTitle: 'Thank you for submitting your feedback!',
  emailNegativeFeedbackSuccessSubTitle: 'Our support team is on it, and we’ll be sure to update you soon.',
  enterValue: 'Enter value...',
};
