import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const StarIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 21 20"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5002 0.00390625L13.5584 6.50083L20.3013 7.5581L15.4373 12.527L16.5859 19.6599L10.5002 16.2857L4.4071 19.664L5.59007 12.527L0.693359 7.559L7.44203 6.50083L10.5002 0.00390625ZM10.5002 3.33027L8.40379 7.784L3.64043 8.53089L7.10569 12.0466L6.28408 17.0035L10.5002 14.6658L14.7239 17.0076L13.9251 12.0467L17.3658 8.53179L12.5967 7.784L10.5002 3.33027Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default StarIcon;
