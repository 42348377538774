export const PATHS = {
  /** We need this path to make google cache works (https://webcache.googleusercontent.com/search?q=cache://www.karmanow.com/) */
  GOOGLE_CACHE_PATH: '/search',

  FAQ: 'https://karmashop.kustomer.support',
  BLOG: 'https://blog.karmanow.com/',
  CHAT_WITH_US_MEETING: 'https://karmashopping-form.youcanbook.me/',
  COMEET: 'https://www.comeet.com/',
  AUTH: '/oauth/:provider',
  DASHBOARD: '/dashboard',
  HOME: '/home',
  HOW_IT_WORKS: '/how-it-works',
  NEW_SALES: '/new-sales',
  WEBSITE_LANDING_PATH: '/',
  WEBSITE_LANDING_MARKETING_2: '/main2',
  WEBSITE_LANDING_MARKETING_5: '/main5',
  WEBSITE_LANDING_BRB: '/brb',
  WEBSITE_LANDING_JOIN: '/join',
  WEBSITE_LANDING_MEN: '/ftg',
  GET_THE_BUTTON: '/get-the-button',

  PROFILE: '/profile',
  PROFILE_WALLET: '/profile/wallet',
  PROFILE_ACCOUNT_SETTINGS: '/profile/account-settings',

  ABOUT_US: '/about-us',
  EXTENSION_DELETED: '/extension-deleted',
  MOBILE_LOGIN: '/mobile-login',

  COUPONS: '/coupons',
  COUPONS__NEWEST: '/coupons/newest',
  COUPONS__POPULAR: '/coupons/popular',

  CAREERS: '/careers',
  CONTACT: '/contact',

  ITEMS: '/my-items',

  STORES: '/supported_stores',
  STORES__FEATURED: '/featured',
  STORES__TRENDING: '/trending',
  STORES__WE_ARE_LOVING: '/all-loving',

  CASHBACK_PICKS: '/cashback-picks',
  CASHBACK_PICK: '/cashback-picks/:name/:id',
  KARMA_CASH: '/karma-cash',
  KARMA_CASH_SINGLE: '/karma-cash/:name/:id',

  RETAILERS: '/retailers',
  RETAILER: '/retailer/:name/:id',

  LANDING_PAGES: '/lp/:id',
  LANDING_PAGES_2: '/lp2/:id',

  REDIRECT: '/redirect',
  REF_INDEX: '/ref/index',
  REF_CAPTURE: '/ref/capture',

  LOGOUT_AND_LOGIN: '/logout_and_login',
  DESKTOP_SWE_LOGIN: '/desktop_swe_login',

  DEMO: {
    SIGN_UP: '/demo/signup',
    V2_EXTENSION: '/demo/welcome',
    SAFARI_STEP_1: '/demo/safari-step-1',
    SAFARI_STEP_2: '/demo/safari-step-2',
    SAFARI_SIGN_UP: '/demo/safari-signup',
  },
  TERMS: '/terms',
  PRIVACY: '/privacy',
  COMEET_PRIVACY: '/privacy-comeet-candidates',

  SEO_TAG: '/shop/:retailer/:brand/:product_name/:tag_id?',
  SEO_TAG_2: '/shop/tag/:tag_id',
  SHOP: '/shop',
  SHARE: '/lists/:token',

  SEO_PROMO: '/promo',
  SEO_PROMO_SEARCH: '/promo/search/:search',
  SEO_PROMO_STORE: '/promo/store/:rule_id',

  UNSUBSCRIBE: '/unsubscribe',
  UNSUBSCRIBED: '/unsubscribed',
  DISABLING_TAGS: '/disabling-tags',
  MUTE_TAG: '/mute_tag',
  DATA_DELETED: '/data_deleted',

  ONBOARDING: {
    IOS_EXTENSION: '/onboarding/ios-extension',
    IOS_EXTENSION_V2: '/onboarding/ios-extensionV2',
  },

  PARTNERSHIPS: '/partnerships',

  EMAIL_POSITIVE_FEEDBACK: '/email-positive-feedback',
  EMAIL_NEGATIVE_FEEDBACK: '/email-negative-feedback',
  EMAIL_NEGATIVE_FEEDBACK_SUCCESS: '/email-negative-feedback-success',

  HEALTHCHECK: '/healthcheck',
  GLOBAL_SEARCH: '/s',

  NOT_FOUND: '/404',
  // For internal use only, not in React app
  NGINX_NOT_FOUND: '/nginx_404',
};

export const SSR_PATHS = [
  PATHS.ABOUT_US,
  PATHS.CAREERS,
  PATHS.KARMA_CASH_SINGLE,
  PATHS.KARMA_CASH,
  PATHS.CONTACT,
  PATHS.GET_THE_BUTTON,
  PATHS.PRIVACY,
  PATHS.COMEET_PRIVACY,
  PATHS.SHARE,
  PATHS.SHOP,
  PATHS.SEO_TAG,
  PATHS.SEO_TAG_2,
  PATHS.SEO_PROMO,
  PATHS.SEO_PROMO_SEARCH,
  PATHS.SEO_PROMO_STORE,
  PATHS.TERMS,
  PATHS.WEBSITE_LANDING_PATH,
  PATHS.GOOGLE_CACHE_PATH,
  PATHS.LANDING_PAGES,
  PATHS.LANDING_PAGES_2,
  PATHS.WEBSITE_LANDING_MARKETING_2,
];

export const PROFILE_PATHS = [PATHS.PROFILE, PATHS.PROFILE_ACCOUNT_SETTINGS, PATHS.PROFILE_WALLET];

export const DASHBOARD_PATHS = [
  PATHS.COUPONS,
  PATHS.COUPONS__NEWEST,
  PATHS.COUPONS__POPULAR,
  PATHS.DASHBOARD,
  PATHS.HOME,
  PATHS.HOW_IT_WORKS,
  PATHS.ITEMS,
  PATHS.NEW_SALES,
  PATHS.RETAILER,
  PATHS.RETAILERS,
  PATHS.STORES,
  PATHS.STORES__FEATURED,
  PATHS.STORES__TRENDING,
  PATHS.STORES__WE_ARE_LOVING,
  PATHS.GLOBAL_SEARCH,
  ...PROFILE_PATHS,
];

export const PROMO_PATHS = [PATHS.SEO_PROMO, PATHS.SEO_PROMO_SEARCH, PATHS.SEO_PROMO_STORE];

export const REDIRECT_PATHS = [PATHS.REDIRECT, PATHS.REF_CAPTURE, PATHS.REF_INDEX];

export const ONBOARDING_PATHS = Object.values(PATHS.ONBOARDING);

export const WEBSITE_LANDING_JOIN_PATHS = [PATHS.WEBSITE_LANDING_BRB, PATHS.WEBSITE_LANDING_JOIN];

export const UTILITY_PATHS = [PATHS.UNSUBSCRIBE, PATHS.DISABLING_TAGS, PATHS.MUTE_TAG];

export const MARKETING_LANDING_PATHS = [
  PATHS.LANDING_PAGES,
  PATHS.LANDING_PAGES_2,
  PATHS.WEBSITE_LANDING_MARKETING_2,
  PATHS.WEBSITE_LANDING_MARKETING_5,
];

export const DEMO_PATHS = Object.values(PATHS.DEMO);
export const SWE_ONBOARDING_PATHS = Object.values(PATHS.ONBOARDING);

export const IGNORE_KUSTOMER_PATHS = [
  PATHS.LOGOUT_AND_LOGIN,
  PATHS.DESKTOP_SWE_LOGIN,
  ...DEMO_PATHS,
  ...SWE_ONBOARDING_PATHS,
  ...REDIRECT_PATHS,
];

export const IGNORE_WISE_POPS_PATHS = [
  PATHS.DATA_DELETED,
  PATHS.LOGOUT_AND_LOGIN,
  PATHS.DESKTOP_SWE_LOGIN,
  PATHS.EXTENSION_DELETED,
  PATHS.AUTH,
  ...REDIRECT_PATHS,
  ...DEMO_PATHS,
  ...PROMO_PATHS,
];

export const DESKTOP_SWE_SCHEMA = {
  LOGIN: 'karma-desktop-swe://login',
};
