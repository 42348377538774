import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const SmileyHeartIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 40 40"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 2.91846C10.5661 2.91846 2.91846 10.5661 2.91846 20C2.91846 29.4339 10.5661 37.0815 20 37.0815C29.4339 37.0815 37.0815 29.4339 37.0815 20C37.0815 10.5661 29.4339 2.91846 20 2.91846ZM0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9263 23.2438C14.5417 25.3945 17.109 26.7811 20.0006 26.7811C22.8922 26.7811 25.4595 25.3945 27.075 23.2438L29.4085 24.9965C27.2655 27.8495 23.8489 29.6995 20.0006 29.6995C16.1523 29.6995 12.7357 27.8495 10.5928 24.9965L12.9263 23.2438Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7165 17.0768L13.0238 19.4999L17.232 15.1523C18.2562 14.0941 18.2562 12.3785 17.232 11.3203C16.2126 10.2671 14.5615 10.2615 13.5353 11.3077L12.9964 11.8571L12.4455 11.2879C11.4285 10.2372 9.77968 10.2372 8.76272 11.2879C7.73953 12.345 7.74672 14.0612 8.77872 15.1091L10.7165 17.0768ZM13.0255 18.4745L13.028 18.4771L13.1833 18.3167L13.0255 18.4745Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.7165 17.0768L27.0238 19.4999L31.232 15.1523C32.2562 14.0941 32.2562 12.3785 31.232 11.3203C30.2126 10.2671 28.5615 10.2615 27.5353 11.3077L26.9964 11.8571L26.4455 11.2879C25.4285 10.2372 23.7797 10.2372 22.7627 11.2879C21.7395 12.345 21.7467 14.0612 22.7787 15.1091L24.7165 17.0768ZM27.0255 18.4745L27.028 18.4771L27.1833 18.3167L27.0255 18.4745Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default SmileyHeartIcon;
