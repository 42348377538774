const ELEMENTS = {
  CASHBACK: 'cashback',
  BUTTON_NOTIFICATIONS: 'notifications',
  BUTTON_QUICK_VIEW: 'quick_view',
  BUTTON_SAVE_ITEM: 'save_item',
  CHAT: 'chat',
  PLUS: 'plus',
  CROSS: 'cross',
  THREE_DOTS: '3_dots',
  DELETE: 'delete',
  DELETE_PRODUCT: 'delete_product',
  NEW_ITEM: 'new_item',
  OPEN_CREATE: 'open_create',
  RIGHT_ARROW: 'right_arrow',
  LEFT_ARROW: 'left_arrow',
  LINK_GO_BACK: 'back',
  LINK_INSTALL: 'install',
  SEE_ALL: 'see_all',
  VIEW_TAG: 'view_tag',
  VIEW_TAG_AT_RETAILER: 'view_tag_at_retailer',
  GET_KARMA_TO_TRACK_ITEM: 'get_karma_to_track_item',
  LINK_TO_COUPONS_SECTION: 'coupons',
  LINK_TO_ALERTS_SECTION: 'alerts',
  LINK_TO_WISHLIST_SECTION: 'wishlist',
  LINK_TO_PAGE_COUPONS: 'coupons',
  RETAILER: 'retailer',
  LINK_TO_PAGE_ITEMS: 'items',
  LINK_TO_PAGE_WALLET: 'wallet',
  PROFILE_ICON: 'profile_icon',
  LINK_TO_PROFILE: 'profile',
  LINK_TO_PROFILE_KARMA_CASH: 'profile_karmaCash',
  LINK_TO_PROFILE_ACCOUNT_SETTINGS: 'profile_account_settings',
  ADD_TO_LIST: 'add_to_list',
  UPDATE_LIST: 'update_list',
  MUTE_NOTIFICATIONS: 'mute_notifications',
  UNMUTE_NOTIFICATIONS: 'unmute_notifications',
  SHARE_PRODUCT: 'share_product',
  SIMILAR_ITEM: 'similar_item',
  REMOVE_FROM_FAVORITES: 'remove_from_favorites',
  ADD_TO_FAVORITES: 'add_to_favorites',
  DONT_SEE_REVIEW: 'dont_see_review',
  TAG: 'tag',
  REDIRECT_LINK_TO_PACKAGE: 'redirect_link_to_package',
  APP_STORE: 'app_store',
  GOOGLE_PLAY: 'google_play',
  LOGO: 'logo',
  BRAND: 'brand',
  INPUT: 'input',
  LIST: 'list',
  OPTION: 'option',
  SELECT_SORT_BY: 'sort_by',
  SHOP_NOW: 'shop_now',
  COUPON: 'coupon',
  QUESTION_BUTTON: 'question_button',
  QUESTION: 'question',
  GO_SHOPPING: 'go_shopping',
  READ_MORE: 'read_more',
  LOAD_MORE: 'load_more',
  NO: 'no',
  YES: 'yes',
  SHOW_LESS: 'show_less',
  SHOW_MORE: 'show_more',
  NOTIFICATION_WITH_COUPON: 'notification_with_coupon',
  NOTIFICATION_ON_SALE: 'notification_on_sale',
  NOTIFICATION_BACK_IN_STOCK: 'notification_back_in_stock',
  NOTIFICATION_LOW_IN_STOCK: 'notification_low_in_stock',
  SAVE: 'save',
  COPY_LINK: 'copy_link',
  GOT_IT: 'got_it',
  SAVE_TO_KARMA: 'save_to_karma',
  SHOPS: 'shops',
  SHOP_NOW_TAG_IMAGE: 'shop_now_tag_image',
  SHOP_NOW_LOGO: 'shop_now_logo',
  SHOP_NOW_TITLE: 'shop_now_title',
  NEXT: 'next',
  PREV: 'prev',
  VIEW_MORE: 'view_more',
  SIGN_UP: 'sign_up',
  GET_THE_APP: 'get_the_app',
  WITHDRAW: 'withdraw',
  TRANSACTIONS: 'transactions',
  PAYOUTS: 'payouts',
  LINK_TO_EDIT_PAY_PAL_PANEL: 'link_to_edit_pay_pal_panel',
  CONNECT_WITH_PAY_PAL: 'connect_with_pay_pal',
  WALLET_SELECT: 'wallet_select',
  REDIRECT_TO_SHOP: 'redirect_to_shop',
  EXPAND_EARNINGS_ITEM: 'expanded_earnings_item',
  CLOSE_PANEL: 'close_panel',
  PRIVACY: 'privacy_policy',
  TERMS: 'terms_and_conditions',
  TWITTER: 'twitter',
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  YOUTUBE: 'youtube',
  TIKTOK: 'tiktok',
  EMAIL: 'email',
  CONTACT_US: 'contact_us',
  HELP_CENTER: 'help_center',
  ABOUT_US: 'about_us',
  PARTNERSHIPS: 'partnerships',
  CAREERS: 'careers',
  DONT_SELL_PERSONAL_INFO: 'dont_sell_my_personal_information',
  BLOG: 'blog',
  LOG_OUT: 'log_out',
  COMPLETE_SIGN_UP: 'complete_sign_up',
  BUY_AT: 'buy_at_shop',
  MAIN_IMAGE: 'main_image',
  SWE_ONBOARDING_OPEN_MOBILE_APP: 'open_mobile_app',
  OPEN_RETAILER: 'open_retailer',
  OPEN_ITEM_RETAILER: 'open_item_retailer',
  SELECT: 'select',
  WHATSAPP: 'whatsapp',
  MESSENGER: 'facebook_messenger',
  FULFILL: 'fulfill',
  MUTE: 'mute',
  CHECK: 'check',
  BIRTHDAY_DAY: 'birtday_day',
  BIRTHDAY_MONTH: 'birtday_month',
  BIRTHDAY_YEAR: 'birtday_year',
  CURRENCY: 'currency',
  GENDER: 'gender',
  LANGUAGE: 'language',
  NEED_IT: 'need_it',
  WANT_IT: 'want_it',
  YES_I_DO_THAT: 'yes_i_do_that',
  GET_THE_BUTTON: 'get_the_button',
  SEND: 'send',
  REINSTALL: 'reinstall',
  COUPONS: 'coupons',
  SELECT_CAUSE: 'select_cause',
  DONE: 'done',
  FORGOT_PASSWORD: 'forgot_password',
  LOG_IN: 'log_in',
  RESET_PASSWORD: 'reset_password',
  CREATE_ACCOUNT: 'create_account',
  CONTINUE_AS_GUEST: 'continue_as_guest',
  JOIN_US: 'join_us',
  OK: 'ok',
  DISCONNECT_FROM_PAYPAL: 'disconnect_from_paypal',
  LOG_IN_AS_USER: 'log_in_as_user',
  CONNECT_NOW: 'connect_now',
  UNDO: 'undo',
  ITEM_ALERTS: 'item_alerts',
  PROMOTIONS_AND_ACTIVITY: 'promotions_and_activity',
  MY_ACCOUNT: 'my_account',
  FAVORITES: 'favorites',
  FULFILLED: 'fulfilled',
  BUTTON_LIST: 'list_button',
  ALL_LISTS: 'all_lists',
  ADD_TO_BROWSER: 'add_to_browser',
  DOWNLOAD_NOW: 'download_now',
  FAVORITE_STORE: 'favorite_store',
  SEE_YOUR_SAVED_ITEMS: 'see_your_saved_items',
  NOTES_WHAT_HAPPENS_NEXT: 'What happens next?',
  NOTES_ABOUT: 'About',
  NOTES_TERMS_AND_CONDITIONS: 'Terms and Conditions',
  HEADER_DOWNLOAD_EXTENSION: 'Header_download_extension',
  HEADER_LOGIN: 'Header_login',
  HERO_DOWNLOAD_EXTENSION: 'Hero_download_extension',
  DOWNLOAD_NOW_ITS_FREE: 'download_now_its_free',
  PROSHOPPER_DOWNLOAD_EXTENSION: 'ProShopper_download_extension',
  PAY_DOWNLOAD_EXTENSION: 'Pay_download_extension',
  ALERTS_DOWNLOAD_EXTENSION: 'Alerts_download_extension',
  WISHLIST_DOWNLOAD_EXTENSION: 'Wishlist_download_extension',
  CASHBACK_DOWNLOAD_EXTENSION: 'Cashback_download_extension',
  TOO_MANY_POP_UPS_WHILE_I_SHOP: 'too_many_pop_ups_while_i_shop',
  COUPONS_DONT_WORK: 'coupons_dont_work',
  DATA_PRIVACY_CONCERNS: 'data_privacy_concerns',
  MY_FAVORITE_STORES_ARE_NOT_SUPPORTED: 'my_favorite_stores_are_not_supported',
  CAUSED_PROBLEMS_WITH_MY_BROWSER: 'caused_problems_with_my_browser',
  OTHER: 'other',
  SCHEDULE_AN_INTERVIEW: 'schedule_an_interview',
  DASHBOARD: 'dashboard',
  NO_RESULTS_FOUND: 'no_results_found',
  OPEN_SEARCH: 'open_search',
  REMOVE: 'remove',
  SEARCH_ACTION: 'search_action',
  SWE_ENABLED: 'swe_enabled',
  VIEW_STORE_INFO: 'view_store_info',
  ADD_STORE: 'add_store',
  FIND_MORE_STORES: 'find_more_stores',
  VISIT_STORE: 'visit_store',
  RETAILER_LOGO: 'retailer_logo',
  RETAILER_NAME: 'retailer_name',
  GO_TO_STORE: 'go_to_store',
  SLIDER_NEXT: 'slider_next',
  SLIDER_PREV: 'slider_prev',
  GET_COUPON: 'get_coupon',
  CANCEL: 'cancel',
  DELETE_MY_ACCOUNT: 'delete_my_account',
  MY_ACCOUNT_EXPAND: 'my_account_expand',
  NOTIFICATIONS: 'notifications',
  SAVED: 'saved',
  X_PLATFORM_BANNER: 'x_platform_banner',
  X: 'x',
  GET_IN_TOUCH: 'get_in_touch',
  APPLY: 'apply',
  CAREERS_EMAIL: 'careers_email',
  EXPAND_OPEN_POSITION: 'expand_open_position',
  CONTRACT_OPEN_POSITION: 'contract_open_position',
  PATENT: 'patent',
  OPEN_POSITIONS: 'open_positions',
  THE_K_BUTTON_IS_IN_MY_WAY: 'the_k_button_is_in_my_way',
  TOO_MANY_COUPON_POPUPS_AT_CHECKOUT: 'too_many_coupon_popups_at_checkout',
  IT_WASNT_HELPFUL: 'it_wasnt_helpful',
  REINSTALL_THE_EXTENSION: 'reinstall_the_extension',
  MANAGE_COOKIES: 'manage_cookies',
  ACCEPT: 'accept',
  DECLINE: 'decline',
  CONFIRM_COOKIE_SETTINGS: 'confirm_cookie_settings',
  COOKIE_STATEMENT: 'cookie_statement',
  START_ONLINE_SHOPPING: 'start_online_shopping',
  START_ONLINE_SHOPPING_ARROW: 'start_online_shopping_arrow',
  USE_CODE: 'use_code',
  GET_DEAL: 'get_deal',
  BUY_NOW: 'buy_now',
  START_BROWSING: 'start_browsing',
  OVERLAY: 'overlay',
  CHROME_WEB_STORE: 'chrome_web_store',
  FREE_TEXT: 'free_text',
  PRICE_MISMATCH: 'price_mismatch',
  ITEM_NOT_ON_SALE: 'item_not_on_sale',
  ITEM_OUT_OF_STOCK: 'item_out_of_stock',
  LINK_NOT_WORKING: 'link_not_working',
  COUPON_CODES_NOT_APPLIED: 'coupon_codes_not_applied',
  COUPON_CODE_NOT_ACCEPTED: 'coupon_code_not_accepted',
  SUBMIT: 'submit',
};

export default ELEMENTS;
