import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const StarIconFilled: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 21 20"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        d="M3.0625 8.55015L8.1997 7.74678L10.4999 2.87305L12.8002 7.74678L17.9374 8.55015L14.2314 12.326L15.1004 17.7085L10.4999 15.1645L5.89946 17.7085L6.794 12.326L3.0625 8.55015Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5029 0L13.6223 6.60924L20.5 7.68479L15.5387 12.7396L16.7102 19.9958L10.5029 16.5632L4.28798 20L5.49461 12.7396L0.5 7.68571L7.38359 6.60924L10.5029 0ZM10.5029 3.38387L8.36458 7.9146L3.50599 8.67439L7.04053 12.2509L6.2025 17.2935L10.5029 14.9154L14.811 17.2977L13.9963 12.2509L17.5057 8.67531L12.6413 7.9146L10.5029 3.38387Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default StarIconFilled;
