import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const GhostIcon: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 24 24"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4701 11.3951C16.6272 12.3647 15.9105 13.1539 15.03 21.7902C14.1494 13.1539 13.4327 12.3647 5.58984 11.3951C13.4327 10.4255 14.1494 9.63628 15.03 1C15.9105 9.63628 16.6272 10.4255 24.4701 11.3951Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66169 5.5098C5.25914 5.93046 4.94821 6.27285 4.5662 10.0196C4.18419 6.27285 3.87325 5.93046 0.470703 5.5098C3.87325 5.08915 4.18419 4.74676 4.5662 1C4.94821 4.74676 5.25914 5.08915 8.66169 5.5098Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5269 19.49C8.12438 19.9106 7.81344 20.253 7.43143 23.9998C7.04942 20.253 6.73849 19.9106 3.33594 19.49C6.73849 19.0693 7.04942 18.7269 7.43143 14.9802C7.81344 18.7269 8.12438 19.0693 11.5269 19.49Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default GhostIcon;
