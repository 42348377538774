import React from 'react';
import BaseIcon, { IIconProps, getIconDimension } from '../../BaseIcon';
import { getColor } from '../../colors';

const CreditCardIconFilled: React.FC<IIconProps> = ({
  size = 'medium',
  width,
  height,
  color = 'currentColor',
  secondaryColor = 'shades2',
  ...props
}) => {
  const iconDimension = getIconDimension(size);

  return (
    <BaseIcon
      viewBox="0 0 27 28"
      width={width || iconDimension}
      height={height || iconDimension}
      fill="none"
      color={getColor(color)}
      {...props}
    >
      <path
        d="M1.66797 9.68814C1.66797 7.25644 3.63925 5.28516 6.07095 5.28516H25.8844V18.4941C25.8844 20.9258 23.9131 22.8971 21.4814 22.8971H1.66797V9.68814Z"
        fill={getColor(secondaryColor)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5996 17.2559C26.5996 20.8123 23.7166 23.6953 20.1602 23.6953L0.952222 23.6953L0.952223 10.3488C0.952224 6.7924 3.83522 3.90939 7.39159 3.90939L26.5996 3.9094L26.5996 17.2559ZM20.1602 21.824C22.6831 21.824 24.7283 19.7788 24.7283 17.2559L24.7283 5.78067L7.39159 5.78066C4.8687 5.78066 2.82349 7.82587 2.82349 10.3488L2.82349 21.824L20.1602 21.824Z"
        fill="currentColor"
      />
      <path d="M1.8877 9.96484H25.6638V12.6066H1.8877V9.96484Z" fill="currentColor" />
    </BaseIcon>
  );
};

export default CreditCardIconFilled;
